import { SET_PAYMENT_GATEWAY_FIELDS_VALUE } from '../../actions/types/types';

const initialState = {
  gatewaysFields: {},
};

const paymentGatewayFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_GATEWAY_FIELDS_VALUE:
      return {
        ...state,
        gatewaysFields: action.payload.data,
      };
    default:
      return state;
  }
};

export default paymentGatewayFieldsReducer;
