import { combineReducers } from "redux";
import LoginReducer from "./login/loginReducer";
import sidebarReducer from "./sidebar/sidebarReducer";
import GymReducer from "./gym/gymReducer";
import SubscriptionPlanReducer from "./subscriptionPlan/subscriptionPlanReducer";
import TrainerReducer from "./trainer/trainerReducer";
import PermissionReducer from "./permission/permissionReducer";
import AdminReducer from "./admin/adminReducer";
import SelectReducer from "./selectReducer";
import MessageReducer from "./messageReducer";
import AccountReducer from "./account/accountReducer";
import SoftwareDashboardReducer from "./dashboard/softwareDashboardReducer";
import PaymentDashboardReducer from "./dashboard/paymentDashboardReducer";
import RevenueDashboardReducer from "./dashboard/revenueDashboardReducer";
import DashboardDataReducer from "./dashboard/dashboardReducer";
import ProductReducer from "./product/productReducer"
import NotificationReducer from "./notification/notificationReducer";
import ReportReducer from "./report/reportReducer";

import { reducer as toastrReducer } from "react-redux-toastr";
import AgreementReducer from "./agreement/agreementReducer";
import MemberReducer from "./member/memberReducer";
import ReviewReducer from "./review/reviewReducer";
import paymentGateways from "./paymentGateways/paymentGatewaysReducer";
import paymentGatewaysFields from "./paymentGateways/paymentGatewaysFieldsReducer";
import paymentGatewayFieldsValue from "./paymentGateways/PaymentGatewayFieldsValueReducer";
import paymentGatewaySaveValueFields from "./paymentGateways/paymentGatewaySaveValueFields";
import paymentGatewaysUsed from "./paymentGateways/paymentGatewaysUsedReducer";

const rootReducer = combineReducers({
  login: LoginReducer,
  toastr: toastrReducer,
  sidebar: sidebarReducer,
  gym: GymReducer,
  plan: SubscriptionPlanReducer,
  trainer: TrainerReducer,
  permissions: PermissionReducer,
  admin: AdminReducer,
  select: SelectReducer,
  message: MessageReducer,
  account: AccountReducer,
  softwareDashboardData: SoftwareDashboardReducer,
  paymentDashboardData: PaymentDashboardReducer,
  revenueDashboardData: RevenueDashboardReducer,
  dashboardData: DashboardDataReducer,
  agreements: AgreementReducer,
  member: MemberReducer,
  review: ReviewReducer,
  product:ProductReducer,
  paymentGateways: paymentGateways,
  paymentGatewaysFields: paymentGatewaysFields,
  paymentGatewayFieldsValue: paymentGatewayFieldsValue,
  paymentGatewaySaveValueFields: paymentGatewaySaveValueFields,
  paymentGatewaysUsed: paymentGatewaysUsed,
  notification: NotificationReducer,
  report:ReportReducer,

});

export default rootReducer;
