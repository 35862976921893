import React, { useEffect, useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import {  useSelector } from "react-redux";
import { history } from "../../app/AppRouter";
const SwitchBranches = () => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  let { branches } = useSelector((state) => state.gym);
  const [listItems, setListItems] = useState([]);
  useEffect(() => {
    if (branches && branches?.length) {
      let items = branches.map((branch) => (
        <DropdownItem
          key={branch.id}
          onClick={() =>
            history.push(`/superadmininfo/branch/redirct/${branch.id}`)
          }
        >
          {branch.user.name}
        </DropdownItem>
      ));
      setListItems(items);
    }
  }, [branches]);
  return (
    <div>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="custom-dropdown-qa"
        direction={"right"}

      >
        <DropdownToggle caret color="transparent" style={{border:'none'}}>
          switch
        </DropdownToggle>
        <DropdownMenu>
          <div className="sub-title"></div>
          {listItems}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SwitchBranches;
