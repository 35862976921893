
import { SET__SAVE_PAYMENT_GATEWAY_FIELDS_VALUE, SET_PAYMENT_GATEWAY_FIELDS_SUCCESS, SET_PAYMENT_GATEWAY_FIELDS_ERROR } from '../../actions/types/types';

const initState = {
    fieldsvalue: [],
};

const paymentGatewaySaveValueFields = (state = initState, action) => {
    switch (action.type) {
        case SET__SAVE_PAYMENT_GATEWAY_FIELDS_VALUE:
            return {
                ...state,
                fieldsvalue: action.payload.data,
            };

        case SET_PAYMENT_GATEWAY_FIELDS_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case SET_PAYMENT_GATEWAY_FIELDS_SUCCESS:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return { ...state };
    }
};

export default paymentGatewaySaveValueFields;
