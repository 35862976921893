import { SET_PERMISSION_DATA, SET_USER_PERMISSION_DATA } from '../types/types';

export const setPermissionData = (value) => {
  return {
    type: SET_PERMISSION_DATA,
    payload: { data: value },
  };
};

export const setUserPermissionData = (value) => {
  return {
    type: SET_USER_PERMISSION_DATA,
    payload: { data: value },
  };
};

export const setSubAdminPermissions = value => {
  return {
    type: "SET_SUB_ADMIN_PERMISSONS",
    payload: { data: value }
  };
};
export const setSubAdminType= value => {
  return {
    type: "SET_SUB_ADMIN_TYPE",
    payload: { data: value }
  };
};