import {
  SET_TRAINERS_DATA,
  SET_TRAINER_DATA,
  SET_TRAINER_COUNT,
  SET_STAFF_DATA,
  SET_STAFF_COUNT,
  
} from '../types/types';

export const setTrainersData = (value) => {
  return {
    type: SET_TRAINERS_DATA,
    payload: { data: value },
  };
};
export const setSubAdminsData = (value) => {
  return {
    type: "SET_SUB_ADMIN_DATA",
    payload: { data: value },
  };
};
export const setSubAdminCount = (value) => {
  return {
    type: "SET_SUB_ADMIN_COUNT",
    payload: { data: value },
  };
};
export const setTrainerData = (value) => {
  return {
    type: SET_TRAINER_DATA,
    payload: { data: value },
  };
};
export const setTrainerCount = (value) => {
  return {
    type: SET_TRAINER_COUNT,
    payload: { data: value },
  };
};
export const setStaffData = (value) => {
  return {
    type: SET_STAFF_DATA,
    payload: { data: value },
  };
};
export const setStaffCount = (value) => {
  return {
    type: SET_STAFF_COUNT,
    payload: { data: value },
  };
};

