const initState = {
  products: [],
  productsCount: 0,
  productsLimit: 10,
  productsPage: 1,
  productHistory: [],
  productHistoryCount: 0,
  productHistoryLimit: 10,
  productHistoryPage: 1,
  productHistoryModal: false,
  editProduct: false,
  product: {},
  suppliers: [],
  productExpiryDates: [],
  productExpiryDatesCount: 0,
  productExpiryDatesLimit: 10,
  productExpiryDatesPage: 1,
  productExpiryDateModal: false,
};

const ProductReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_PRODUCTS":
      return {
        ...state,
        products: action.payload.data,
      };

    case "SET_PRODUCTS_COUNT":
      return {
        ...state,
        productsCount: action.payload.data,
      };
    case "SET_PRODUCTS_EXPIRY_DATES":
      return {
        ...state,
        productExpiryDates: action.payload.data,
      };
    case "SET_PRODUCTS_EXPIRY_DATES_COUNT":
      return {
        ...state,
        productExpiryDatesCount: action.payload.data,
      };
      case "SET_PRODUCTS_EXPIRY_DATES_LIMIT":
        return {
          ...state,
          productExpiryDatesLimit: action.payload.data,
        };
        case "SET_PRODUCTS_EXPIRY_DATES_PAGE":
          return {
            ...state,
            productExpiryDatesPage: action.payload.data,
          };
    case "SET_PRODUCTS_PAGE":
      return {
        ...state,
        productsPage: action.payload.data,
      };
    case "SET_PRODUCTS_LIMIT":
      return {
        ...state,
        productsLimit: action.payload.data,
      };
    case "SET_PRODUCT_HISTORY":
      return {
        ...state,
        productHistory: action.payload.data,
      };

    case "SET_PRODUCT_HISTORY_COUNT":
      return {
        ...state,
        productHistoryCount: action.payload.data,
      };
    case "SET_PRODUCT_HISTORY_PAGE":
      return {
        ...state,
        productHistoryPage: action.payload.data,
      };
    case "SET_PRODUCT_HISTORY_LIMIT":
      return {
        ...state,
        productHistoryLimit: action.payload.data,
      };

    case "SET_PRODUCTS_EDIT":
      return {
        ...state,
        editProduct: action.payload.data,
      };

    case "SET_PRODUCT":
      console.log("here");
      return {
        ...state,
        product: action.payload.data,
      };

    case "SET_PRODUCTS_HISTORY_MODAL":
      return {
        ...state,
        productHistoryModal: action.payload.data,
      };
    case "SET_SUPPLIERS":
      return {
        ...state,
        suppliers: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default ProductReducer;
