import React, { useEffect, useState } from "react";
import { ProductInfoEdit } from "../../../contents/htmlContent";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Title from "../../title";
import { Field } from "formik";
import moment from "moment";

export const InventorySection = ({
  errors,
  touched,
  values,
  setFieldValue,
  edit,
}) => {
  const measures = ["Piece", "Dozen", "Container", "Box","Pack"];
  return (
    <>
      <div className="info-product-title mt-4 ">
        <Title title={ProductInfoEdit.inventory} />
      </div>
      <div
        className="trainer-gender-default-wrapper border-bottom-1 blk-padding-y"
        style={{
          backgroundColor: "#F5F7F8",
        }}
      >
        <div className="info trainer-info pl-1">
          <Row>
            <Col md={3}>
              <FormGroup className="custom-inputs ">
                <Field
                  type="number"
                  name="stock"
                  id="stock"
                  placeholder={ProductInfoEdit.stockPlaceHolder}
                  className={`form-control ${
                    errors?.stock && touched?.stock && "is-invalid"
                  }`}
                />
                {errors?.stock && touched.stock ? (
                  <div className="invalid-feedback">{`${errors?.stock}`}</div>
                ) : null}
                <Label for="name" style={{ backgroundColor: "#F5F7F8" }}>
                  {ProductInfoEdit.stock}
                </Label>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="custom-inputs ">
                <Input
                  type="select"
                  name="measureOfPurchase"
                  id="measureOfPurchase"
                  placeholder={"Measure of Purchase"}
                  className={`form-control ${
                    errors.measureOfPurchase && "is-invalid"
                  }`}
                  value={values.measureOfPurchase}
                  onChange={(e) => {
                    setFieldValue("measureOfPurchase", e.target.value);
                  }}
                >
                  <option value="">select</option>
                  {measures.map((measure) => {
                    return <option>{measure}</option>;
                  })}
                </Input>
                {errors.measureOfPurchase ? (
                  <div className="invalid-feedback">{`${errors.measureOfPurchase}`}</div>
                ) : null}
                <Label
                  for="name"
                  className=""
                  style={{ backgroundColor: "#F5F7F8" }}
                >
                  {"Measure of Purchase"}
                </Label>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="custom-inputs ">
                <Field
                  type="number"
                  name="purchasePrice"
                  id="purchasePrice"
                  placeholder={"Purchase Price"}
                  className={`form-control`}
                />
                <Label for="name" style={{ backgroundColor: "#F5F7F8" }}>
                  {`Purchase Price / per ${values.measureOfPurchase}`}
                </Label>
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup className="custom-inputs ">
                <Field
                  type="number"
                  value={values.stock * values.purchasePrice}
                  maxLength={50}
                  disabled={true}
                  placeholder={ProductInfoEdit.costPlaceHolder}
                  className={`form-control`}
                />

                <Label for="name" style={{ backgroundColor: "#F5F7F8" }}>
                  {ProductInfoEdit.cost}
                </Label>
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup className="custom-inputs ">
                <Field
                  type="number"
                  name="minInStock"
                  id="minInStock"
                  disabled={edit}
                  placeholder={"Min Quantity"}
                  className={`form-control ${
                    errors.minInStock && "is-invalid"
                  }`}
                />
                <Label for="name" style={{ backgroundColor: "#F5F7F8" }}>
                  {"Min in Stock"}
                </Label>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="custom-inputs ">
                <Input
                  type="select"
                  name="measureOfUnit"
                  id="measureOfUnit"
                  placeholder={"Measure of Unit"}
                  className={`form-control ${
                    errors.measureOfUnit && "is-invalid"
                  }`}
                  value={values.measureOfUnit}
                  onChange={(e) => {
                    setFieldValue("measureOfUnit", e.target.value);
                  }}
                >
                  <option value="">select</option>
                  {measures.map((measure) => {
                    return <option>{measure}</option>;
                  })}
                </Input>
                {errors.measureOfUnit ? (
                  <div className="invalid-feedback">{`${errors.measureOfUnit}`}</div>
                ) : null}
                <Label
                  for="name"
                  className=""
                  style={{ backgroundColor: "#F5F7F8" }}
                >
                  {"MOU"}
                </Label>
              </FormGroup>
              {values.measureOfPurchase &&
                values.measureOfUnit &&
                values.measureOfPurchase !== values.measureOfUnit && (
                  <>
                    {" "}
                    <h5 className="text-center">Convert Measures :</h5>{" "}
                    <Row className="justify-content-center">
                      <Col>
                        <FormGroup className="custom-inputs ">
                          <Field
                            type="number"
                            name="source"
                            id="source"
                            disabled={edit}
                            placeholder={"Min Quantity"}
                            className={`form-control ${
                              errors.source && "is-invalid"
                            }`}
                          />
                          <h5 className="text-center mt-2">
                            {values?.measureOfPurchase}
                          </h5>{" "}
                        </FormGroup>
                      </Col>
                      <Col xs="auto" md="auto">
                        <h3 className="text-center pt-3">=</h3>{" "}
                      </Col>
                      <Col className="py-0 ma-0">
                        <FormGroup className="custom-inputs ">
                          <Field
                            type="number"
                            name="target"
                            id="target"
                            disabled={edit}
                            className={`form-control ${
                              errors.target && "is-invalid"
                            }`}
                          />
                          <h5 className="text-center mt-2">
                            {values?.measureOfUnit}
                          </h5>{" "}
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
            </Col>
            <Col md={3}>
              <FormGroup className="custom-inputs ">
                <DatePicker
                  colorPrimary="#7b00a3"
                  calendarPopperPosition={"left"}
                  onChange={(date) => {
                    console.log({ date });
                    const formatString = "DD/M/YYYY";
                    let fomatDate = `${date.day}/${
                      date.month.length == 1 ? `0 ${date.month}` : date.month
                    }/${date.year}`;
                    console.log({ fomatDate });
                    const parsedDate = moment(fomatDate, formatString);
                    if (date) setFieldValue("expiryDate", parsedDate);
                  }}
                  renderInput={({ ref }) => (
                    <>
                      <input
                        // readOnly
                        ref={ref} // necessary
                        placeholder="Expiry Date"
                        value={
                          values.expiryDate?._i
                            ? values.expiryDate._i
                            : typeof values.expiryDate == "number"
                            ? moment(values.expiryDate).format("DD/MM/YY")
                            : values.expiryDate
                        }
                        className={`form-control ${
                          errors.expiryDate && "is-invalid"
                        }`}
                      />
                      <Label style={{ backgroundColor: "#F5F7F8" }}>
                        {ProductInfoEdit.expiryDate}
                      </Label>
                    </>
                  )}
                />
                {errors.expiryDate && touched.expiryDate ? (
                  <div className="invalid-feedback">{`${errors.expiryDate}`}</div>
                ) : null}
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup className="custom-inputs ">
                <Field
                  type="text"
                  name="supplier"
                  id="supplier"
                  maxLength={50}
                  placeholder={ProductInfoEdit.supplierPlaceHolder}
                  className={`form-control ${
                    errors.supplier && touched.supplier && "is-invalid"
                  }`}
                />
                {errors.supplier && touched.supplier ? (
                  <div className="invalid-feedback">{`${errors.supplier}`}</div>
                ) : null}
                <Label
                  for="name"
                  className=""
                  style={{ backgroundColor: "#F5F7F8" }}
                >
                  {ProductInfoEdit.supplier}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
