export const setProducts = (value) => {
  return {
    type: "SET_PRODUCTS",
    payload: { data: value },
  };
};

export const setProductsCount = (value) => {
  return {
    type: "SET_PRODUCTS_COUNT",
    payload: { data: value },
  };
};
export const setProductExpiryDates = (value) => {
  return {
    type: "SET_PRODUCTS_EXPIRY_DATES",
    payload: { data: value },
  };
};
export const setProductExpiryDatesCount = (value) => {
  return {
    type: "SET_PRODUCTS_EXPIRY_DATES_COUNT",
    payload: { data: value },
  };
};
export const setProductExpiryDatesLimit = (value) => {
  return {
    type: "SET_PRODUCTS_EXPIRY_DATES_LIMIT",
    payload: { data: value },
  };
};
export const setProductExpiryDatesPage = (value) => {
  return {
    type: "SET_PRODUCTS_EXPIRY_DATES_PAGE",
    payload: { data: value },
  };
};
export const setSuppliers = (value) => {
  return {
    type: "SET_SUPPLIERS",
    payload: { data: value },
  };
};

export const setProductsPage = (value) => {
  return {
    type: "SET_PRODUCTS_PAGE",
    payload: { data: value },
  };
};
export const setProductsLimit = (value) => {
  return {
    type: "SET_PRODUCTS_LIMIT",
    payload: { data: value },
  };
};
export const setProductHistory = (value) => {
  return {
    type: "SET_PRODUCT_HISTORY",
    payload: { data: value },
  };
};

export const setProductHistoryCount = (value) => {
  return {
    type: "SET_PRODUCT_HISTORY_COUNT",
    payload: { data: value },
  };
};
export const setProductHistoryPage = (value) => {
  return {
    type: "SET_PRODUCT_HISTORY_PAGE",
    payload: { data: value },
  };
};
export const setProductHistoryLimit = (value) => {
  return {
    type: "SET_PRODUCT_HISTORY_LIMIT",
    payload: { data: value },
  };
};
export const setProductHitoryModal = (value) => {
  return {
    type: "SET_PRODUCTS_HISTORY_MODAL",
    payload: { data: value },
  };
};
export const setProductEdit = (value) => {
  return {
    type: "SET_PRODUCTS_EDIT",
    payload: { data: value },
  };
};
export const setProduct = (value) => {
  return {
    type: "SET_PRODUCT",
    payload: { data: value },
  };
};
