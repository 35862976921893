import React, { useEffect, useRef, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Col, FormGroup, Label, Button } from "reactstrap";
import { Field } from "formik";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";
import { ProductInfoEdit } from "../../../contents/htmlContent";
import { ComonIcons } from "../../../contents/commonIcons";

export const BarcodeSection = ({
  errors,
  touched,
  values,
  setFieldValue,
  edit,
}) => {
  console.log({ bar: values?.barcode });

  const [counter, setCounter] = useState([0]);

  const generateBarcode = () => {
    /**
     * we have array of barcodes [111,222,333] product.barcode
     *  will have a counter =1
     *  check if counter show input of barcode onChange product.barcode[counter-1]=e.target.value
     *  check if product.barcode [counter-1]
     *    -- show image  product.barcode[counter-1]
     *   check if i  ==1
     *    -- show the 3 buttons
     * for each barcode their is an image
     * there are three buttons (add,generate,scan)
     *   -- if add :
     *     -- a new row will be generated and image next to it and counter will be increased
     *   -- if generate
     *     -- product.barcode[counter-1]=generateBarcode()
     *
     */
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 13; // You can adjust the length of the barcode as per your requirement
    let barcode = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      barcode += characters[randomIndex];
    }

    return barcode;
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (values.barcode && values?.barcode.length) {
      let counterArr = [];
      values.barcode.map((el, ind) => {
        counterArr.push(ind);
      });
      setCounter(counterArr);
    }
  }, [values]);

  return (
    <>
      {counter.map((count) => {
        return (
          <>
            <Col md={values?.barcode && values?.barcode[count] ? 3 : 6}>
              <FormGroup className="custom-inputs ">
                <Field
                  type="text"
                  value={
                    values?.barcode && values?.barcode[count]
                      ? values?.barcode[count]
                      : null
                  }
                  id={count}
                  maxLength={13}
                  placeholder={ProductInfoEdit.barcodePlaceHolder}
                  className={`form-control `}
                  onChange={(e) => {
                    let barcodes = [];
                    if (values.barcode.length) {
                      barcodes = [...values.barcode];
                    }
                    barcodes[count] = e.target.value;
                    setFieldValue("barcode", barcodes);
                  }}
                />
                {errors.barcode && touched.barcode ? (
                  <div className="invalid-feedback">{`${errors.barcode}`}</div>
                ) : null}
                <Label for="barcode" className="">
                  {ProductInfoEdit.barcode}
                </Label>
              </FormGroup>
            </Col>

            {values?.barcode && values?.barcode[count] ? (
              <Col md={3} className="mt-n2 ml-n4 ">
                <Barcode
                  value={values?.barcode[count]}
                  height="50"
                  width={1.5}
                />
              </Col>
            ) : null}
            {values?.barcode && values?.barcode[count] ? (
              <Col md={1} className="mr-4 barcode-container">
                <div onClick={handlePrint} className="print-icon">
                  {ComonIcons.download}
                  <div style={{ display: "none" }}>
                    {/* This component won't be displayed */}
                    <div ref={componentRef}>
                      {values?.barcode &&
                        values?.barcode.map((barcodeValue, index) => (
                          <div className="barcode-container">
                            {/* Render your Barcode component here */}
                            <Barcode
                              value={barcodeValue}
                              height={50}
                              width={1.5}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Col>
            ) : null}

            {count == 0 ? (
              <Col md={5}>
                <Button
                  onClick={() => {
                    let numberOfBarcodes = [...counter];
                    numberOfBarcodes.push(
                      numberOfBarcodes[numberOfBarcodes.length - 1] + 1
                    );
                    setCounter(numberOfBarcodes);
                  }}
                  className={"mr-2"}
                >
                  Add Barcode
                </Button>
                <Button
                  className="mr-2"
                  onClick={(e) => {
                    let barcodes = [];
                    if (values.barcode.length) {
                      barcodes = [...values.barcode];
                    }
                    barcodes[count] = generateBarcode();
                    setFieldValue("barcode", barcodes);
                  }}
                >
                  Generate Barcode
                </Button>
              </Col>
            ) : (
              <Col md={5}>
                <Button
                  onClick={() => {
                    // delete from counter
                    let numberOfBarcodes = [...counter];
                    numberOfBarcodes.splice(count, 1);
                    setCounter(numberOfBarcodes);
                    // delete from values.barcode
                    let barcodes = [...values.barcode];
                    barcodes.splice(count, 1);
                    setFieldValue("barcode", barcodes);
                  }}
                  className={"mr-2"}
                >
                  Delete Barcode
                </Button>
                <Button
                  className="mr-1"
                  onClick={(e) => {
                    let barcodes = [];
                    if (values.barcode.length) {
                      barcodes = [...values.barcode];
                    }
                    barcodes[count] = generateBarcode();
                    setFieldValue("barcode", barcodes);
                  }}
                >
                  Generate Barcode
                </Button>
              </Col>
            )}
          </>
        );
      })}
    </>
  );
};
