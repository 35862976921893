import {
    SET_NOTIFICATIONS_DATA,
    SET_UNREAD_NOTIFICATION_COUNT,
    SET_PAGE_LIMIT
  } from '../../actions/types/types';
  
  const initState = {
    unreadNotificationCount: 0,
    notifications: [],
    page: 1,
    limit:10,
    totalCount:0
  };
  
  const NotificationReducer = (state = initState, action) => {
    switch (action.type) {
      case SET_NOTIFICATIONS_DATA:
        return {
          ...state,
          notifications: action.payload.data,
          totalCount: action.payload.totalCount
        };
      case SET_UNREAD_NOTIFICATION_COUNT:
        return {
          ...state,
          unreadNotificationCount: action.payload.data,
        };
      case SET_PAGE_LIMIT:
        return {
          ...state,
          page: action.payload.page,
          limit: action.payload.limit
        };
      default:
        return { ...state };
    }
  };
  
  export default NotificationReducer;
  