import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addGymModal,
  addTrainerModal,
  addAdminModal,
  addSubAdminModal,
  addProductModal,
} from "../../redux/actions/sidebar/sidebarAction";
import { getPlans, getPermissions } from "../../redux/actions/api/apiAction";
const AddDropdown = (props) => {
  const [dropdownOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { loginUser } = useSelector((state) => state.login);
  const { permissions, subAdminType, subAdminPermissions } = useSelector(
    (state) => state.permissions
  );
  const toggle = () => setOpen(!dropdownOpen);
  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="w-100">
      <DropdownToggle
        caret
        className="custom-dropdown-button w-100"
        color="transparent"
      >
        Add
      </DropdownToggle>
      <DropdownMenu className="add-dropdown-menu">
        <DropdownItem
          onClick={async () => {
            dispatch(addGymModal(true));
          }}
        >
          <span className="block-1">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Admin-account"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                opacity="0.5"
              >
                <g
                  id="Admin-Dashboard"
                  transform="translate(-32.000000, -594.000000)"
                >
                  <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
                    <g id="Gyms" transform="translate(0.000000, 513.000000)">
                      <g
                        id="fitness_center-24px"
                        transform="translate(32.000000, 11.000000)"
                      >
                        <polygon
                          id="Path"
                          points="0 0 24 0 24 24 0 24"
                        ></polygon>
                        <path
                          d="M20.57,14.86 L21.29,14.14 C21.68,13.75 21.68,13.12 21.29,12.73 L21.27,12.71 C20.88,12.32 20.25,12.32 19.86,12.71 L17,15.57 L8.43,7 L11.29,4.14 C11.68,3.75 11.68,3.12 11.29,2.73 L11.27,2.71 C10.88,2.32 10.25,2.32 9.86,2.71 L9.14,3.43 L8.42,2.71 C8.03,2.32 7.39,2.32 7,2.71 L5.57,4.14 L4.85,3.42 C4.46,3.03 3.81,3.03 3.42,3.42 C3.03,3.81 3.03,4.46 3.42,4.85 L4.14,5.57 L2.71,7 C2.32,7.39 2.32,8.02 2.71,8.41 L3.43,9.13 L2.71,9.86 C2.32,10.25 2.32,10.88 2.71,11.27 L2.73,11.29 C3.12,11.68 3.75,11.68 4.14,11.29 L7,8.43 L15.57,17 L12.71,19.86 C12.32,20.25 12.32,20.88 12.71,21.27 L12.73,21.29 C13.12,21.68 13.75,21.68 14.14,21.29 L14.86,20.57 L15.58,21.29 C15.97,21.68 16.6,21.68 16.99,21.29 L18.42,19.86 L19.14,20.58 C19.53,20.97 20.18,20.97 20.57,20.58 C20.96,20.19 20.96,19.54 20.57,19.15 L19.85,18.43 L21.29,17 C21.68,16.61 21.68,15.98 21.29,15.59 L20.57,14.86 Z"
                          id="Path"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span className="block-2">Add Branch</span>
        </DropdownItem>
        <DropdownItem
          onClick={async () => {
            dispatch(addSubAdminModal(true));
          }}
        >
          <span className="block-1">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>796B5807-4CEB-49EC-B356-C9F0BFAC9012@1.5x</title>
              <g
                id="Gym-Admin"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Staff-Page"
                  transform="translate(-32.000000, -569.000000)"
                >
                  <g id="Group-15" transform="translate(0.000000, 536.000000)">
                    <g id="Gyms" transform="translate(0.000000, 22.000000)">
                      <g
                        id="people_outline_black_24dp"
                        transform="translate(32.000000, 11.000000)"
                      >
                        <polygon
                          id="Path"
                          points="0 0 24 0 24 24 0 24"
                        ></polygon>
                        <path
                          d="M9,12 C10.93,12 12.5,10.43 12.5,8.5 C12.5,6.57 10.93,5 9,5 C7.07,5 5.5,6.57 5.5,8.5 C5.5,10.43 7.07,12 9,12 Z M9,7 C9.83,7 10.5,7.67 10.5,8.5 C10.5,9.33 9.83,10 9,10 C8.17,10 7.5,9.33 7.5,8.5 C7.5,7.67 8.17,7 9,7 Z M9,13.75 C6.66,13.75 2,14.92 2,17.25 L2,18 C2,18.55 2.45,19 3,19 L15,19 C15.55,19 16,18.55 16,18 L16,17.25 C16,14.92 11.34,13.75 9,13.75 Z M4.34,17 C5.18,16.42 7.21,15.75 9,15.75 C10.79,15.75 12.82,16.42 13.66,17 L4.34,17 Z M16.04,13.81 C17.2,14.65 18,15.77 18,17.25 L18,19 L21,19 C21.55,19 22,18.55 22,18 L22,17.25 C22,15.23 18.5,14.08 16.04,13.81 Z M15,12 C16.93,12 18.5,10.43 18.5,8.5 C18.5,6.57 16.93,5 15,5 C14.46,5 13.96,5.13 13.5,5.35 C14.13,6.24 14.5,7.33 14.5,8.5 C14.5,9.67 14.13,10.76 13.5,11.65 C13.96,11.87 14.46,12 15,12 Z"
                          id="Shape"
                          fill="#8700A9"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span className="block-2">Add Admin</span>
        </DropdownItem>
        {(loginUser.role === "STAFF" && !permissions["S-ADD-PRO"]) ||
        (subAdminType === "STAFF" &&
          !subAdminPermissions["S-ADD-PRO"]) ? null : (
          <DropdownItem
            onClick={async () => {
              dispatch(addProductModal(true));
            }}
          >
            <span className="block-1">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Admin-account"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                  opacity="0.5"
                >
                  <g
                    id="Admin-Dashboard"
                    transform="translate(-32.000000, -594.000000)"
                  >
                    <g
                      id="Side-Menu"
                      transform="translate(0.000000, 70.000000)"
                    >
                      <g id="Gyms" transform="translate(0.000000, 513.000000)">
                        <g
                          id="fitness_center-24px"
                          transform="translate(32.000000, 11.000000)"
                        >
                          <polygon
                            id="Path"
                            points="0 0 24 0 24 24 0 24"
                          ></polygon>
                          <path
                            d="M20.57,14.86 L21.29,14.14 C21.68,13.75 21.68,13.12 21.29,12.73 L21.27,12.71 C20.88,12.32 20.25,12.32 19.86,12.71 L17,15.57 L8.43,7 L11.29,4.14 C11.68,3.75 11.68,3.12 11.29,2.73 L11.27,2.71 C10.88,2.32 10.25,2.32 9.86,2.71 L9.14,3.43 L8.42,2.71 C8.03,2.32 7.39,2.32 7,2.71 L5.57,4.14 L4.85,3.42 C4.46,3.03 3.81,3.03 3.42,3.42 C3.03,3.81 3.03,4.46 3.42,4.85 L4.14,5.57 L2.71,7 C2.32,7.39 2.32,8.02 2.71,8.41 L3.43,9.13 L2.71,9.86 C2.32,10.25 2.32,10.88 2.71,11.27 L2.73,11.29 C3.12,11.68 3.75,11.68 4.14,11.29 L7,8.43 L15.57,17 L12.71,19.86 C12.32,20.25 12.32,20.88 12.71,21.27 L12.73,21.29 C13.12,21.68 13.75,21.68 14.14,21.29 L14.86,20.57 L15.58,21.29 C15.97,21.68 16.6,21.68 16.99,21.29 L18.42,19.86 L19.14,20.58 C19.53,20.97 20.18,20.97 20.57,20.58 C20.96,20.19 20.96,19.54 20.57,19.15 L19.85,18.43 L21.29,17 C21.68,16.61 21.68,15.98 21.29,15.59 L20.57,14.86 Z"
                            id="Path"
                            fill="#000000"
                            fill-rule="nonzero"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span className="block-2">Add Product</span>
          </DropdownItem>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default AddDropdown;
