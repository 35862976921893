import {
  SET_REPORTS_DATA,
  SET_REPORTS_COUNT,
  SET_REPORTS_SUMMARY,
} from "../../actions/types/types";

const initState = {
  reports: [],
  reportsCount: 0,
  reportSummary: {},
  page: 1,
  limit: 10,
  details: [],
  detailsCount: 0,
  sumDetails: {},
  detailsPage: 1,
  detailsLimit: 10,
  listClasses: [],
  reportQuery: {},
  allReports: [],
  financialParties: [],
  financialPartiesCount: 0,
  financialPartieslimit: 10,
  financialPartiesPage: 1,
  showPackageFilter: false,
  showProductFilter: false,
  showTemplate: false,
  templateUrl: null,
};

const ReportReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_REPORTS_COUNT:
      return {
        ...state,
        reportsCount: action.payload.data,
      };

    case SET_REPORTS_DATA:
      console.log(action.payload.data,"acccccccccccccccccccccccccccccccctttttttttttttttttttttttttttt")
      return {
        ...state,
        reports: action.payload.data,
      };
    case "SET_ALL_REPORTS_DATA":
      return {
        ...state,
        allReports: action.payload.data,
      };
    case "SET_SHOW_TEMPLATE":
      return {
        ...state,
        showTemplate: action.payload.data,
      };
    case "SET_TEMPLATE_URL":
      return {
        ...state,
        templateUrl: action.payload.data,
      };

    case SET_REPORTS_SUMMARY:
      return {
        ...state,
        reportSummary: action.payload.data,
      };
    case "SET_REPORT_PAGE":
      return {
        ...state,
        page: action.payload.data,
      };
    case "SET_REPORT_LIMIT":
      return {
        ...state,
        limit: action.payload.data,
      };
    case "SET_BALANCE_DETAILS_LIMIT":
      return {
        ...state,
        detailsLimit: action.payload.data,
      };
    case "SET_BALANCE_DETAILS_PAGE":
      return {
        ...state,
        detailsPage: action.payload.data,
      };

    case "SET_SALES_DETAILS_DATA":
      return {
        ...state,
        details: action.payload.data,
      };
    case "SET_SALES_DETAILS_COUNT":
      return {
        ...state,
        detailsCount: action.payload.data,
      };
    case "SET_SALES_TOTAL_SUM":
      return {
        ...state,
        sumDetails: { ...state.sumDetails, ...action.payload.data },
      };
    case "SET_LIST_CLASSES":
      return {
        ...state,
        listClasses: action.payload.data,
      };
    case "SET_REPORT-QUERY":
      return {
        ...state,
        reportQuery: action.payload.data,
      };
    case "SET_FINANCIAL_PARTIES":
      return {
        ...state,
        financialParties: action.payload.data,
      };

    case "SET_FINANCIAL_PARTIES_COUNT":
      return {
        ...state,
        financialPartiesCount: action.payload.data,
      };
    case "SET_FINANCIAL_LIMIT":
      return {
        ...state,
        financialPartieslimit: action.payload.data,
      };
    case "SET_SHOW_PACKAGE_FILTER":
      return {
        ...state,
        showPackageFilter: action.payload.data,
      };
    case "SET_SHOW_PRODUCT_FILTER":
      return {
        ...state,
        showProductFilter: action.payload.data,
      };

    case "SET_FINANCIAL_PAGE":
      return {
        ...state,
        financialPartiesPage: action.payload.data,
      };

    default:
      return { ...state };
  }
};

export default ReportReducer;
