import React from "react";
// import { Button } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import { addUserModal, setRetailModal } from "../../../redux/actions/sidebar/sidebarAction";
import { store } from "../../../redux/storeConfig/store";
import { history } from "../../../app/AppRouter";
import { setProductEdit } from "../../../redux/actions/product/productAction";
import { addProductModal } from "../../../redux/actions/sidebar/sidebarAction";

export const StepButtonForProduct = (props) => {
  //console.log("stepButtonForProduct", props);

  return (
    <>
      <div className="bg-white card-panel ex-space-y pt-0">
        <div className="action-wrapper blk-padding-y">
          <div className="action-inner-block">
            <div className="action-btn-block">
              <button
                className="btn btn-medium btn-transparent"
                type="button"
                onClick={() => {
                  if(props.edit){
                    store.dispatch(setProductEdit(false))

                  }else{
                    store.dispatch(addProductModal(false))

                  }
                }}
              >
                Cancel
              </button>
              <button
                value="Add Instructor"
                className="btn btn-medium"
                type="submit"
              >
                {props.edit ? "Update" : "Add"} Product
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
