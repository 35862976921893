import { SET_PERMISSION_DATA, SET_USER_PERMISSION_DATA } from '../../actions/types/types';

const initState = {
  permissions: [],
  userPermissions:[],
  subAdminPermissions:{},
  subAdminType:""

};

const PermissionReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PERMISSION_DATA:
      return {
        ...state,
        permissions: action.payload.data,
      };
    case SET_USER_PERMISSION_DATA:
      return {
        ...state,
        userPermissions: action.payload.data,
      };
      case "SET_SUB_ADMIN_PERMISSONS"
      :
      return {
        ...state,
        subAdminPermissions: action.payload.data
      };
    case "SET_SUB_ADMIN_TYPE"
      :
      return {
        ...state,
        subAdminType: action.payload.data
      };
    default:
      return { ...state };
  }
};

export default PermissionReducer;
