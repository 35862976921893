import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import Title from "../../../components/title";
import { FormGroup, Label, Row, Col, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { addGymModal } from "../../../redux/actions/sidebar/sidebarAction";
import { useDispatch, useSelector, } from "react-redux";
import { history } from "../../../app/AppRouter";
import Resizer from "react-image-file-resizer";
import { api } from "../../../api/api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import ReactFlagsSelect from "react-flags-select";
import { createGym, getGovernances } from "../../../redux/actions/api/apiAction";


const formSchema = Yup.object().shape({
  name: Yup.string().required("Gym name is required"),
  phone: Yup.number().required("Valid phone number is required "),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Admin email is required"),
});

const DropZone = ({ setFieldValue, }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      let file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async () => {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            "PNG",
            100,
            0,
            async (uri) => {
              let data = {
                fileName: acceptedFiles[0].name,
                source: uri,
                folder: "profile",
              };
              var url = await api(`file`, data, "post");
              if (url.data.status === 201) {
                setFieldValue("image", url.data.data);
              }
            },
            "base64",
            300,
            300
          );
        };
        reader.readAsDataURL(file);
      }
    },
    [setFieldValue]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: 10485760,
    accept: "image/*",
  });

  return (
    <div className="upload-profilephoto">
      <div className="file-input" {...getRootProps()}>
        <h6 className="upload-link mb-0">Upload Picture </h6>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

function GymForm({
  name,
  email,
  phone,
  subscriptionPlan,
  startDate,
  endDate,
  canLive,
  edit,
  editTrainer,
  image,
  userType,
  country,
  ...props
}) {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setisPhoneNumberValid] = useState(true);

  const { governaces } = useSelector((state) => state.gym)
  useEffect(() => {
    const isValid =
      phoneNumber && isValidPhoneNumber(phoneNumber) ? true : false;
    setisPhoneNumberValid(isValid);
  }, [phoneNumber, setisPhoneNumberValid]);

  const handleSubmit = (values) => {
    console.log(values);
    dispatch(createGym(values))
  }
  useEffect(() => {
    dispatch(getGovernances())
  }, [])

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          name,
          email,
          phone,
          image,
          country: JSON.parse(localStorage.getItem('user')).country,
          governace: ""
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form className="admin-details">
              <div className="profile-image-block blk-padding-y border-bottom-1">
                <div className="profile-image-inner-block d-flex justify-content-center">
                  <div className="profile-image-wrapper">
                    <div className="profile-image">
                      <img
                        src={
                          values.image
                            ? values.image
                            : require("../../../assets/images/no-image_user.jpg")
                        }
                        className="img-fluid img-blk"
                        alt=""
                      />
                    </div>
                    <div className="profile-image-links">
                      <div className="links file-input-link">
                        <DropZone
                          values={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                        />
                      </div>
                      <div className="links">
                        <h6
                          className="remove-link mb-0 text-uppercase"
                          onClick={async () => {
                            setFieldValue("image", "");
                          }}
                        >
                          Remove Picture{" "}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-wrapper border-bottom-1 blk-padding-y">
                <div className="info-title">
                  <Title title={"Branch info"} />
                </div>
                <div className="info">
                  <Row>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <Field
                          type="text"
                          name="name"
                          id="adminname"
                          maxLength={50}
                          placeholder={
                            "Branch Name"
                          }
                          className={`form-control pr-5 ${errors.name && touched.name && "is-invalid"
                            }`}
                        />
                        {errors.name && touched.name ? (
                          <div className="invalid-feedback">{`${errors.name}`}</div>
                        ) : null}
                        <Label for="firstname" className="">
                          {"Branch Name"}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <Field
                          disabled={edit}
                          type="email"
                          name="email"
                          id="adminname"
                          maxLength={50}
                          placeholder="Username"
                          className={`form-control pr-5 ${errors.email && touched.email && "is-invalid"
                            }`}
                        />
                        {errors.email && touched.email ? (
                          <div className="invalid-feedback">{`${errors.email}`}</div>
                        ) : null}
                        <Label>Admin Email</Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <PhoneInput
                          international
                          defaultCountry={JSON.parse(localStorage.getItem('user')).country}
                          value={values.phone}
                          placeholder="Phone Number"
                          onChange={(value) => {
                            if (value) {
                              setPhoneNumber(value);
                              if (isValidPhoneNumber(value)) {
                                setFieldValue("phone", value);
                              } else {
                                setFieldValue("phone", "");
                              }
                            }
                          }}
                          className={`h3 input-title ${errors.phone && touched.phone && "is-invalid"
                            }`}
                        />
                        {errors.phone && touched.phone ? (
                          <div className="invalid-feedback">{`${errors.phone}`}</div>
                        ) : null}
                        <Label>Phone Number</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="gym-live-training-wrapper border-bottom-1 blk-padding-y">
                <Row>
                  <Col md={2}>
                    <div className="info-title">
                      <Title title="Country" />
                    </div>
                    <div className="status-inner-block">
                      <div className="time-select-box d-inline-block">
                        <div className="d-flex align-items-center">
                          <div className="switch-wrapper line-switch">
                            <ReactFlagsSelect
                              selected={values.country}
                              countries={["JO", "SA", "AE", "EG", "IQ", "QA"]}
                              searchable
                              onSelect={(val) => {
                                setFieldValue("country", val);
                              }}
                            />
                            {errors.country ? (
                              <div className="invalid-feedback d-block">{`${errors.country}`}</div>
                            ) : null}
                          </div>

                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={10}>
                    <div style={{ height: "34px" }}>
                    </div>
                    <div className="status-inner-block">
                      <div className="time-select-box d-inline-block">
                        <div className="d-flex align-items-center">
                          <div className="switch-wrapper line-switch">
                            <FormGroup className="custom-inputs mb-md-0">

                              <Input
                                type="select"
                                name="select"
                                id="exampleSelect"
                                onChange={e => {
                                  setFieldValue("governance", e.target.value);

                                }}
                                className={`form-control pr-2 `}
                              >
                                <option value="" className="h3 input-title">Select Governance</option>

                                {governaces.map((el) => {
                                  return (
                                    <option value={el.id}>{el?.name}</option>

                                  )
                                })}

                              </Input>
                              <Label>Governance</Label>

                            </FormGroup>
                          </div>



                        </div>
                      </div>
                    </div>
                  </Col>

                </Row>

              </div>
              <div className="action-wrapper blk-padding-y pb-0">
                <div className="action-inner-block">
                  <div className="action-btn-block">
                    <button
                      value="cancel"
                      type="reset"
                      className="btn btn-medium btn-transparent"
                      onClick={() => {
                        if (editTrainer) {
                          history.goBack();
                        } else {
                          edit
                            ? history.goBack()
                            : dispatch(addGymModal(false));
                        }
                      }}
                    >
                      cancel
                    </button>
                    <button className="btn btn-medium" type="submit">
                      {edit ? "Save changes" : "Add Gym"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}

GymForm.defaultProps = {
  name: "",
  email: "",
  phone: "",
  handleSubmit: (values) => { },
  edit: false,
  editTrainer: false,
  user: false,
  type: "",
  image: "",
  userType: "",
  country: "",
};

export default GymForm;
