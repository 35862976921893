import {
  SET_TRAINER_DATA,
  SET_TRAINERS_DATA,
  SET_TRAINER_COUNT,
  ADD_NEW_TRAINER,
  SET_STAFF_DATA,
  SET_STAFF_COUNT,
} from '../../actions/types/types';

const initState = {
  trainers: [],
  trainer: {},
  trainerCount: 0,
  staff: undefined,
  staffCount: undefined,
  subAdmins: [],
  subAdminsCount: 0
};

const TrainerReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TRAINER_DATA:
      return {
        ...state,
        trainer: action.payload.data,
      };
    case "SET_SUB_ADMIN_DATA":
      return {
        ...state,
        subAdmins: action.payload.data,
      };
    case "SET_SUB_ADMIN_COUNT":
      return {
        ...state,
        subAdminsCount: action.payload.data,
      };
    case SET_TRAINERS_DATA:
      return {
        ...state,
        trainers: action.payload.data,
      };
    case SET_TRAINER_COUNT:
      return {
        ...state,
        trainerCount: action.payload.data,
      };
    case ADD_NEW_TRAINER:
      return {
        ...state,
        trainers: [...state.trainers, action.payload.data],
      };
    case SET_STAFF_DATA:
      return {
        ...state,
        staff: action.payload.data,
      };
    case SET_STAFF_COUNT:
      return {
        ...state,
        staffCount: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default TrainerReducer;

