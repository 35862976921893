import {
  SET_REPORTS_DATA,
  SET_REPORTS_COUNT,
  SET_REPORTS_SUMMARY,
} from "../types/types";

export const setReportsCount = (value) => {
  return {
    type: SET_REPORTS_COUNT,
    payload: { data: value },
  };
};
export const setReportsData = (value) => {
  console.log({value})
  return {
    type: SET_REPORTS_DATA,
    payload: { data: value },
  };
};
export const setShowTemplate = (value) => {
  return {
    type: "SET_SHOW_TEMPLATE",
    payload: { data: value },
  };
};

export const setTemplateUrl = (value) => {
  return {
    type: "SET_TEMPLATE_URL",
    payload: { data: value },
  };
};



export const setAllReportsData = (value) => {
  console.log({ value });
  return {
    type: "SET_ALL_REPORTS_DATA",
    payload: { data: value },
  };
};

export const setReportsSummary = (value) => {
  return {
    type: SET_REPORTS_SUMMARY,
    payload: { data: value },
  };
};
export const setReportPage = (value) => {
  return {
    type: "SET_REPORT_PAGE",
    payload: { data: value },
  };
};
export const setBalanceDetailsLimit = (value) => {
  return {
    type: "SET_BALANCE_DETAILS_LIMIT",
    payload: { data: value },
  };
};
export const setReportLimit = (value) => {
  return {
    type: "SET_REPORT_LIMIT",
    payload: { data: value },
  };
};
export const setBalanceDetailsPage = (value) => {
  return {
    type: "SET_BALANCE_DETAILS_PAGE",
    payload: { data: value },
  };
};

export const setSalesDetailsData = (value) => {
  return {
    type: "SET_SALES_DETAILS_DATA",
    payload: { data: value },
  };
};

export const setSalesDetailsCount = (value) => {
  return {
    type: "SET_SALES_DETAILS_COUNT",
    payload: { data: value },
  };
};

export const setSalesTotalData = (value) => {
  return {
    type: "SET_SALES_TOTAL_SUM",
    payload: { data: value },
  };
};
export const setClassesList = (value) => {
  return {
    type: "SET_LIST_CLASSES",
    payload: { data: value },
  };
};

export const setQuery = (value) => {
  return {
    type: "SET_REPORT-QUERY",
    payload: { data: value },
  };
};
export const setFinancialPartyPage = (value) => {
  return {
    type: "SET_FINANCIAL_PAGE",
    payload: { data: value },
  };
};
export const setShowPackageFilter = (value) => {
  return {
    type: "SET_SHOW_PACKAGE_FILTER",
    payload: { data: value },
  };
};
export const setShowProductFilter = (value) => {
  return {
    type: "SET_SHOW_PRODUCT_FILTER",
    payload: { data: value },
  };
};

export const setFinancialPartyLimit = (value) => {
  return {
    type: "SET_FINANCIAL_LIMIT",
    payload: { data: value },
  };
};
export const setFinancialParties = (value) => {
  return {
    type: "SET_FINANCIAL_PARTIES",
    payload: { data: value },
  };
};
export const setFinancialPartiesCount = (value) => {
  return {
    type: "SET_FINANCIAL_PARTIES_COUNT",
    payload: { data: value },
  };
};
