export const SET_LOGIN_USER = "SET_LOGIN_USER";
export const SET_LOGIN_FLAG = "SET_LOGIN_FLAG";
export const SET_USER_STATE = "SET_USER_STATE";
export const SET_SIDEBAR_STATUS = "SET_SIDEBAR_STATUS";

export const ADD_GYM_MODAL = "ADD_GYM_MODAL";
export const ADD_TRAINER_MODAL = "ADD_TRAINER_MODAL";
export const ADD_PRODUCT_MODAL = "ADD_PRODUCT_MODAL";

export const ADD_NEW_TRAINER = "ADD_NEW_TRAINER";
export const ADD_ADMIN_MODAL = "ADD_ADMIN_MODAL";
export const ADD_SUB_ADMIN_MODAL = "ADD_SUB_ADMIN_MODAL";

export const SET_GYM_DATA = "SET_GYM_DATA";
export const SET_GYMS_DATA = "SET_GYMS_DATA";
export const SET_GYM_COUNT = "SET_GYM_COUNT";
export const SET_SUPER_GYM_ADMIN_INFO = "SET_SUPER_GYM_ADMIN_INFO";
export const SET_SUPER_GYM_ADMIN_TDASHBOARD_TOTALS =
  "SET_SUPER_GYM_ADMIN_TDASHBOARD_TOTALS";
export const SET_BRANCHES_DATA = "SET_BRANCHES_DATA";
export const SET_PLAN_DATA = "SET_PLAN_DATA";
export const SET_TRAINERS_DATA = "SET_TRAINERS_DATA";
export const SET_STAFF_DATA = "SET_STAFF_DATA";
export const SET_STAFF_COUNT = "SET_STAFF_COUNT";
export const SET_TRAINER_DATA = "SET_TRAINER_DATA";

export const SET_TRAINER_COUNT = "SET_TRAINER_COUNT";
export const SET_NOTIFICATIONS_DATA = "SET_NOTIFICATIONS_DATA";
export const SET_UNREAD_NOTIFICATION_COUNT = "SET_UNREAD_NOTIFICATION_COUNT";
export const SET_PAGE_LIMIT = "SET_PAGE_LIMIT";
export const ALERT_MODAL = "ALERT_MODAL";
export const SET_SUBSCRIBEDMEMBERS_DATA = "SET_SUBSCRIBEDMEMBERS_DATA";
export const SET_SUBSCRIBEDMEMBERS_COUNT = "SET_SUBSCRIBEDMEMBERS_COUNT";

export const SET_PERMISSION_DATA = "SET_PERMISSION_DATA";
export const SET_ADMIN_DATA = "SET_ADMIN_DATA";
export const SET_ADMINS_DATA = "SET_ADMINS_DATA";
export const SET_ADMIN_COUNT = "SET_ADMIN_COUNT";

export const SET_ACCOUNTS_DATA = "SET_ACCOUNTS_DATA";
export const SET_ACCOUNT_DATA = "SET_ACCOUNT_DATA";
export const SET_ACCOUNT_COUNT = "SET_ACCOUNT_COUNT";

export const SET_MEMBERS = "SET_MEMBERS";
export const SET_MEMBERS_COUNT = "SET_MEMBERS_COUNT";
export const SET_MEMBER_DATA = "SET_MEMBER_DATA";
export const SET_MEMBERS_DATA = "SET_MEMBERS_DATA";
export const SET_MEMBER_COUNT = "SET_MEMBER_COUNT";

export const SET_MESSAGE_DATA = "SET_MESSAGE_DATA";
export const SET_SOFTWARE_DASHBOARD_CARD_DATA =
  "SET_SOFTWARE_DASHBOARD_CARD_DATA";
export const SET_USER_PERMISSION_DATA = "SET_USER_PERMISSION_DATA";
export const SET_TERMS = "SET_TERMS";
export const SET_PRIVACY_POLICY = "SET_PRIVACY_POLICY";
export const SET_DASHBOARD_CARD_DATA = "SET_DASHBOARD_CARD_DATA";
export const SET_BOOKING_CARD_DATA = "SET_BOOKING_CARD_DATA";

export const SET_SOFTWARE_DASHBOARD_ACCOUNTS_DATA =
  "SET_SOFTWARE_DASHBOARD_ACCOUNTS_DATA";
export const SET_SOFTWARE_DASHBOARD_ACCOUNT_DATA =
  "SET_SOFTWARE_DASHBOARD_ACCOUNT_DATA";
export const SET_SOFTWARE_DASHBOARD_ACCOUNT_COUNT =
  "SET_SOFTWARE_DASHBOARD_ACCOUNT_COUNT";

export const SET_PAYMENT_DASHBOARD_ACCOUNTS_DATA =
  "SET_PAYMENT_DASHBOARD_ACCOUNTS_DATA";
export const SET_PAYMENT_DASHBOARD_ACCOUNT_DATA =
  "SET_PAYMENT_DASHBOARD_ACCOUNT_DATA";
export const SET_PAYMENT_DASHBOARD_ACCOUNT_COUNT =
  "SET_PAYMENT_DASHBOARD_ACCOUNT_COUNT";

export const SET_REVENUE_DASHBOARD_ACCOUNTS_DATA =
  "SET_REVENUE_DASHBOARD_ACCOUNTS_DATA";
export const SET_REVENUE_DASHBOARD_ACCOUNT_DATA =
  "SET_REVENUE_DASHBOARD_ACCOUNT_DATA";
export const SET_REVENUE_DASHBOARD_ACCOUNT_COUNT =
  "SET_REVENUE_DASHBOARD_ACCOUNT_COUNT";

export const SET_ALL_ACCOUNTS = "SET_ALL_ACCOUNTS";
export const SET_LIVE_CLASS_CARD_DATA = "SET_LIVE_CLASS_CARD_DATA";
export const SET_BOOKINGS = "SET_BOOKINGS";
export const SET_SALES = "SET_SALES";
export const SET_EXPANSION_COUNT = "SET_EXPANSION_COUNT";
export const SET_EXPANSION_SUMMARY = "SET_EXPANSION_SUMMARY";

export const SET_REVIEW_LIST = "SET_REVIEW_LIST";
export const SET_REVIEW_COUNT = "SET_REVIEW_COUNT";

export const SET_PAYEMNT_GATEWAYS_BY_REGION = "SET_PAYEMNT_GATEWAYS_BY_REGION";
export const SET_PAYMENT_GATEWAYS_FIELDS = "SET_PAYMENT_GATEWAYS_FIELDS";
export const SET_PAYMENT_GATEWAY_FIELDS_VALUE =
  "SET_PAYMENT_GATEWAY_FIELDS_VALUE";
export const SET__SAVE_PAYMENT_GATEWAY_FIELDS_VALUE =
  "SET__SAVE_PAYMENT_GATEWAY_FIELDS_VALUE";
export const SET_PAYMENT_GATEWAY_FIELDS_ERROR =
  "SET_PAYMENT_GATEWAY_FIELDS_ERROR";
export const SET_PAYMENT_GATEWAY_FIELDS_SUCCESS =
  "SET_PAYMENT_GATEWAY_FIELDS_SUCCESS";
export const SET_PAYMENT_GATEWAYS_USED = "SET_PAYMENT_GATEWAYS_USED";

export const SET_REPORTS_DATA = "SET_REPORTS_DATA";
export const SET_REPORTS_COUNT = "SET_REPORTS_COUNT";
export const SET_REPORTS_SUMMARY = "SET_REPORTS_SUMMARY";
