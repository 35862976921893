

import { SET_PAYMENT_GATEWAYS_FIELDS } from '../../actions/types/types';

const initState = {
  paymentGatewaysFields: [],
};

const paymentGatewaysFields = (state = initState, action) => {
    switch (action.type) {
      case SET_PAYMENT_GATEWAYS_FIELDS:
        return {
          ...state,
          paymentGatewaysFields: action.payload.data,
        };
      default:
        return { ...state };
    }
  };

  export default paymentGatewaysFields;
