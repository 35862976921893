import React, { useCallback, useEffect } from "react";
import * as Yup from "yup";
import { ProfilePicture, MembersInfoEdit } from "../../../contents/htmlContent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Modal,
  ModalBody,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import Title from "../../title";
import { Field } from "formik";
import Webcam from "react-webcam";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { api } from "../../../api/api";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { store } from "../../../redux/storeConfig/store";
import BranchList from "./branchesList";
import { useSelector } from "react-redux";

const DropZone = ({ values, setFieldValue, errors }) => {
  const maxSize = 10485760;
  const onDrop = useCallback((acceptedFiles) => {
    //console.log(values);
    //console.log(acceptedFiles[0]);
    //console.log("acceptedFiles", acceptedFiles);
    let file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        //console.log(event.target.result);
        Resizer.imageFileResizer(
          file,
          300,
          300,
          "PNG",
          100,
          0,
          async (uri) => {
            let data = {
              fileName: acceptedFiles[0].name,
              source: uri,
              folder: "profile",
            };
            var url = await api(`file`, data, "post");
            if (url.data.status === 201) {
              //console.log(uri);
              setFieldValue("image", url.data.data);
            }
          },
          "base64",
          300,
          300
        );
      };
      reader.readAsDataURL(file);
    }
  }, []);
  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: 10485760,
    accept: "image/*",
  });
  return (
    <div className="upload-profilephoto">
      <div className="file-input" {...getRootProps()}>
        <h6 className="upload-link mb-0">Upload Picture </h6>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

export const FormContent = (formikProps) => {
  const {
    errors,
    touched,
    values,
    setFieldValue,
    edit,
    gymId,
    loggedInGym,
    permissionsList,
  } = formikProps;
  const { branches } = useSelector((state) => state.gym);

  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [phoneNumber, setPhoneNumber] = React.useState(values.phone);
  const [takePicture, setTakePicture] = React.useState(false);
  const [selectAll, setSelectedAll] = React.useState(false);
  const [groupedPermissions, setGroupedPermissions] = React.useState({});

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    let data = {
      fileName: "captured",
      source: imageSrc,
      folder: "profile",
    };
    var url = await api(`file`, data, "post");
    if (url.data.status === 201) {
      setFieldValue("image", url.data.data);
    }
    setImgSrc(imageSrc);
    setTakePicture(false);
  }, [webcamRef, setImgSrc]);

  useEffect(() => {
    if (store.getState().sidebar.subAdminEdit) {
      let { name, phone, image, email, permissions, title ,userGyms} =
        store.getState().sidebar.subAdminFields;
      setFieldValue("name", name);
      setFieldValue("phone", phone);
      setPhoneNumber(phone);
      setFieldValue("image", image);
      setFieldValue("email", email);
      setFieldValue("title", title);
      setFieldValue(
        "permissions",
        permissions?.map((el) => el.id)
      );
    //  setFieldValue("branches",userGyms)
     console.log({userGyms})

      setSelectedAll(permissions.length === permissionsList.length);
    }
  }, []);
  useEffect(() => {
    if (store.getState().sidebar?.subAdminEdit && permissionsList) {
      let { permissions } = store.getState().sidebar.subAdminFields;
      let custmPermission = permissions.map((itm) => itm.id);

      setFieldValue("permissions", custmPermission);
      setSelectedAll(custmPermission.length === permissionsList.length);
    }
    // Grouping the array by category
    let groupedPermissions = permissionsList.reduce((groups, item) => {
      let { category, ...rest } = item;
      if (!groups[category]) {
        if (!category) {
          category = "Not Categorized";
        }
        groups[category] = [];
      }
      groups[category].push(rest);
      return groups;
    }, {});

    let sortedGroupedPermissions = Object.entries(groupedPermissions)
      .sort((a, b) => a[1].length - b[1].length)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    setGroupedPermissions(sortedGroupedPermissions);
    console.log({ groupedPermissions, permissionsList });
  }, [permissionsList]);

  // useEffect(() => {
  //   if (store.getState().sidebar?.subAdminEdit && permissionsList) {

  //     let { permissions } = store.getState().sidebar.subAdminFields;
  //     let custmPermission = permissions.map((itm) => {
  //       return `${itm.id}`;
  //     });
  //     setFieldValue("permissions", custmPermission);
  //     setSelectedAll(custmPermission.length === permissionsList.length);
  //   }
  // }, [permissionsList]);
  return (
    <>
      <div className=" bg-white card-panel">
        <div className="basic-info">
          <div className="info-title with-border">
            <Title
              title="Add Admin"
              subtitlestyle="ctf-tx-secondary"
              subtitle="Add info about the Admin"
            />
          </div>
        </div>
        <div className="profile-image-block blk-padding-y border-bottom-1">
          <div className="profile-image-inner-block d-flex justify-content-center">
            <div className="profile-image-wrapper">
              {takePicture ? (
                <div className="captureimage">
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                  />
                </div>
              ) : (
                <div className="profile-image">
                  <img
                    src={
                      values.image
                        ? values.image
                        : require("../../../assets/images/no-image_user.jpg")
                    }
                    className="img-fluid img-blk"
                    alt="user"
                  />
                </div>
              )}

              <div className="profile-image-links">
                {/* <div className="links file-input-link">
                  <div
                    className="file-input"
                    onClick={() => {
                      takePicture ? capture() : setTakePicture(true);
                    }}
                  >
                    <h6 className="upload-link mb-0">
                      {!takePicture ? ProfilePicture.takePicture : "Capture"}
                    </h6>
                  </div>
                </div> */}
                <div className="links file-input-link">
                  <div className="file-input">
                    <DropZone
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="links">
                  <h6
                    className="remove-link mb-0 text-uppercase"
                    onClick={async () => {
                      if (values.image) {
                        // await api(`file`, { url: values.image }, "delete");
                        setFieldValue("image", "");
                      }
                    }}
                  >
                    {ProfilePicture.removePicture}{" "}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="member-info-wrapper info-wrapper border-bottom-1 blk-padding-y">
          <div className="info-title">
            <Title title={"Admin Info"} />
          </div>
          <div className="info trainer-info">
            <Row>
              <Col md={4}>
                <FormGroup className="custom-inputs ">
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    maxLength={50}
                    disabled={edit && gymId !== loggedInGym}
                    placeholder="Admin name"
                    className={`form-control ${
                      errors.name && touched.name && "is-invalid"
                    }`}
                  />
                  {errors.name && touched.name ? (
                    <div className="invalid-feedback">{`${errors.name}`}</div>
                  ) : null}
                  <Label for="firstname" className="">
                    Admin name
                  </Label>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className="custom-inputs mb-md-0">
                  <Field
                    type="text"
                    name="email"
                    id="username"
                    disabled={edit && gymId !== loggedInGym}
                    placeholder="user name "
                    className={`form-control ${
                      errors.email && touched.email && "is-invalid"
                    }`}
                  />
                  {errors.email && touched.email ? (
                    <div className="invalid-feedback">{`${errors.email}`}</div>
                  ) : null}
                  <Label> Username</Label>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup className="custom-inputs">
                  <PhoneInput
                    international
                    defaultCountry={
                      JSON.parse(localStorage.getItem("user")).country
                    }
                    // disabled={edit && gymId !== loggedInGym}
                    value={phoneNumber}
                    placeholder="Phone Number"
                    onChange={(value) => {
                      //console.log(value, "value of phone");
                      if (value) {
                        setPhoneNumber(value);
                        if (isValidPhoneNumber(value))
                          setFieldValue("phone", value);
                        else setFieldValue("phone", "");
                      }
                    }}
                    className={` ${
                      errors.phone && touched.phone && "is-invalid"
                    }`}
                  />
                  {errors.phone && touched.phone ? (
                    <div className="invalid-feedback">{`${errors.phone}`}</div>
                  ) : null}

                  <Label>Phone number</Label>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
        {/* {store.getState().sidebar.subAdminEdit && ( */}
          <div className="member-info-wrapper info-wrapper border-bottom-1 blk-padding-y">
            <div className="info-title">
              <Title title={"Access Branches"} />
            </div>
            <div className="info trainer-info">
              <BranchList branches={branches} setFieldValue={setFieldValue} selected={store.getState().sidebar.subAdminFields?.userGyms}/>
            </div>
          </div>
        {/* )} */}

        {/* <div className="member-info-wrapper info-wrapper border-bottom-1 blk-padding-y">
          <div className="info-title">
            <Title title={"Admin Permissions"} />
          </div>
          <div className="info trainer-info">
            <Row>
            <Col md={12}>
                <div className="subscription-plan-list-element">
                  <div className="selction-box mt-0 px-0">
                    <div className="checkboxes-list form-check">
                      <label
                        className="container-blk form-check-label"
                      >
                        <Field
                          className="form-check-input"
                          type="checkbox"
                          name="permissions"
                          checked={
                            selectAll
                          }
                          onChange={() => {
                            const allPermissionIds = permissionsList.map((itm) => `${itm.id}`);
                            setFieldValue('permissions', !selectAll ? allPermissionIds : []);
                            setSelectedAll(!selectAll)
                          }}
                        />
                        <span class="checkmark"></span>
                        select All                    </label>
                    </div>
                  </div>
                </div>
              </Col>
              {permissionsList &&
                permissionsList.map((itm, idx) => {
                  return (
                    <Col md={6}>
                      <div className="subscription-plan-list-element">
                        <div className="selction-box mt-0 px-0">
                          <div className="checkboxes-list form-check">
                            <label
                              className="container-blk form-check-label"
                              for={`checkbox${idx}`}
                            >
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name="permissions"
                                id={`checkbox${idx}`}
                                value={itm.id}
                                checked={
                                  values.permissions &&
                                  values.permissions.includes(`${itm.id}`)
                                  
                                }
                                onChange={(e) => {
                                  const permissionId = e.target.value;
                                  const updatedPermissions = values.permissions.includes(permissionId)
                                    ? values.permissions.filter((id) => id !== permissionId)
                                    : [...values.permissions, permissionId];

                                  setFieldValue('permissions', updatedPermissions);
                                  setSelectedAll(updatedPermissions.length === permissionsList.length)
                                }}
                              />
                              <span class="checkmark"></span>
                              {itm.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              {errors.permissions && touched.hasOwnProperty("permissions") ? (
                <div className="invalid-feedback d-block ml-3">{`${errors.permissions}`}</div>
              ) : null}
            </Row>
          </div>
        </div> */}
        <div className="member-info-wrapper info-wrapper border-bottom-1 blk-padding-y">
          <div className="info-title">
            <Title title={"Sub Admin Permissions"} />
          </div>
          <div className="info trainer-info">
            <Row>
              {permissionsList.length ? (
                <Col md={12}>
                  <div className="subscription-plan-list-element">
                    <div className="selction-box mt-0 px-0">
                      <div className="checkboxes-list form-check">
                        <label className="container-blk form-check-label">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="permissions"
                            checked={selectAll}
                            onChange={() => {
                              const allPermissionIds = permissionsList.map(
                                (itm) => parseInt(itm.id)
                              );
                              console.log({ allPermissionIds });
                              setFieldValue(
                                "permissions",
                                !selectAll ? allPermissionIds : []
                              );
                              setSelectedAll(!selectAll);
                            }}
                          />
                          <span class="checkmark"></span>
                          select All{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
              ) : (
                <span style={{ fontSize: "14px", marginLeft: "15px" }}>
                  There are no categories selected yet !
                </span>
              )}
              {Object.keys(groupedPermissions).map((category) => (
                <>
                  <Col
                    key={category}
                    lg={3}
                    style={{
                      borderTop: "1px solid #eeeeee",
                      marginBottom: "10px",
                    }}
                  >
                    <div className="subscription-plan-list-element mt-4">
                      <div className="selction-box mt-0 px-0">
                        <div className="checkboxes-list form-check">
                          <label
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                            id="permission-category"
                          >
                            {category}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <span>{category}</span> */}
                    {console.log(
                      groupedPermissions[category],
                      "groupedPermissions[category]"
                    )}
                    {/* Iterate over the permissions array for the current category */}
                    {groupedPermissions[category].map((permission, idx) => (
                      <Col md={12} lg={12}>
                        <div className="subscription-plan-list-element">
                          <div className="selction-box mt-0 px-0">
                            <div className="checkboxes-list form-check">
                              <label
                                className="container-blk form-check-label"
                                for={`checkbox${permission?.id}`}
                                id="permission-category"
                              >
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="permissions"
                                  id={`checkbox${permission?.id}`}
                                  value={permission.id}
                                  checked={
                                    values.permissions &&
                                    values.permissions.includes(permission.id)
                                  }
                                  onChange={(e) => {
                                    console.log({ permission, idx });
                                    const permissionId = parseInt(
                                      e.target.value
                                    );

                                    let updatedPermissions;
                                    let permissions = values.permissions
                                      ? [...values.permissions]
                                      : [];

                                    if (permissions.includes(permissionId)) {
                                      console.log(" include", permissions);
                                      // Add the permission if it's checked and not already in the list
                                      updatedPermissions = permissions.filter(
                                        (id) => id !== permissionId
                                      );
                                      console.log(
                                        " include",
                                        updatedPermissions
                                      );
                                    } else {
                                      console.log("not include");

                                      updatedPermissions = [
                                        ...permissions,
                                        permissionId,
                                      ];
                                      console.log(
                                        "not include",
                                        updatedPermissions
                                      );
                                    }
                                    setFieldValue(
                                      "permissions",
                                      updatedPermissions
                                    );
                                  }}
                                />
                                <span class="checkmark"></span>
                                {permission.name}
                              </label>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Col>
                </>
              ))}

              {/* {permissionsList &&
                permissionsList.map((itm, idx) => {
                  return (
                    <Col md={6}>
                      <div className="subscription-plan-list-element">
                        <div className="selction-box mt-0 px-0">
                          <div className="checkboxes-list form-check">
                            <label
                              className="container-blk form-check-label"
                              for={`checkbox${idx}`}
                              id="permission-category"
                            >
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name="permissions"
                                id={`checkbox${idx}`}
                                value={itm.id}
                                checked={
                                  values.permissions &&
                                  values.permissions.some(
                                    (obj) => obj.id == itm.id
                                  )
                                }
                                onChange={(e) => {
                                  const permissionId = e.target.value;
                                  console.log(
                                    values.permissions.some(
                                      (obj) => obj.id == itm.id
                                    )
                                  );
                            
                                  console.log({ e, permi: values.permissions });
                                  const updatedPermissions = values.permissions.some(
                                    (obj) => obj.id == itm.id
                                  )
                                    ? values.permissions.filter(
                                        (item) => item.id !== permissionId
                                      )
                                    : [
                                        ...values.permissions,
                                        {
                                          id: permissionId,
                                          category: itm.category,
                                        },
                                      ];

                                  setFieldValue(
                                    "permissions",
                                    updatedPermissions
                                  );
                                  setSelectedAll(
                                    updatedPermissions.length ===
                                      permissionsList.length
                                  );
                                }}
                              />
                              <span class="checkmark"></span>
                              {itm.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })} */}
              {errors.permissions && touched.hasOwnProperty("permissions") ? (
                <div className="invalid-feedback d-block ml-3">{`${errors.permissions}`}</div>
              ) : null}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
