import React, { useEffect, useState } from "react";
import { Formik, Form, yupToFormErrors } from "formik";
import { FormFirstStep } from "./firstStepForm";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SpinnerComponent from "../../spinner/spinner";
import { store } from "../../../redux/storeConfig/store";
import {
  createProduct,
  editGym,
  getSuperGymAdminInfo,
  updateProduct,
} from "../../../redux/actions/api/apiAction";
import { StepButtonForProduct } from "./stepButtonForProduct";
import moment from "moment";
import Swal from "sweetalert2";
import { toastr } from "react-redux-toastr";

// export const MultiStep = () => {
function MultiStepForRetail({
  name,
  type,
  price,
  tax,
  cost,
  barcode,
  category,
  stock,
  minInStock,
  supplier,
  expiryDate,
  note,
  handleSubmit,
  measureOfPurchase,
  measureOfUnit,
  weight,
  productType,
  purchasePrice,
  weightUnit,
  target,
  source,
}) {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();

  const { superGymAdminInfo } = useSelector((state) => state.gym);
  const { loginUser } = useSelector((state) => state.login);
  handleSubmit = async (values) => {
    Swal.fire({
      title: "Confirm",
      text: "Are you sure you want to create this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#4D4467",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, create it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log({ values });
        let payload = {
          ...values,
          netPrice: values.price,
          cost: values.purchasePrice * values.stock,
          expiryDate: moment(values?.expiryDate).valueOf(),
          existingCategories: superGymAdminInfo?.category
            ? superGymAdminInfo.category
            : [],
        };
        await store.dispatch(createProduct(payload));
      }
    });
  };

  const submit = (values) => {
    handleSubmit(values);
  };

  const previous = () => setStep((step) => step - 1);

  const formSchema1 = Yup.object().shape({
    image: Yup.string(),
    name: Yup.string().required("Product Name is required"),
    // expiryDate: Yup.date(),
    type: Yup.string(),
    price: Yup.number("Product Price must be more than 0")
      .min(1, "Product Price must be more than 0")
      .required("Product Price is required"),
    tax: Yup.number().required("Product Tax is required"),
    barcode: Yup.array(),
    category: Yup.string().required("Product Category is required"),
    stock: Yup.number()
      .min(0, "Number in stock cant  be less than 0")
      .required("Number in stock is required"),
    supplier: Yup.string().required("Product supplier is required"),
    measureOfPurchase: Yup.string().required(
      "Product Measure of Purchase is required"
    ),
    measureOfUnit: Yup.string().required("Product Measure of Unit is required"),
    minInStock: Yup.string().required(
      "Product min  of Available Quantity is required"
    ),
    expiryDate: Yup.date().required("Expiry Date is required"),
  });

  // const { loading } = useSelector((state) => state.gym);
  // if (loading)
  //   return (
  //     <div
  //       style={{ height: "800px" }}
  //       className="d-flex flex-column align-items-center justify-content-center"
  //     >
  //       <SpinnerComponent />
  //       <h3>Please wait</h3>
  //     </div>
  //   );
  useEffect(() => {
    dispatch(getSuperGymAdminInfo());
  }, []);
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          name,
          type,
          price,
          tax,
          cost,
          barcode,
          category,
          stock,
          supplier,
          expiryDate,
          note,
          measureOfPurchase,
          measureOfUnit,
          minInStock,
          weight,
          productType,
          purchasePrice,
          weightUnit,
          target,
          source,
        }}
        onSubmit={submit}
        validationSchema={formSchema1}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <FormFirstStep
              errors={errors}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
            />

            <StepButtonForProduct step={step} previous={previous} />
          </Form>
        )}
      </Formik>
    </>
  );
}
MultiStepForRetail.defaultProps = {
  name: "",
  type: "",
  price: 0,
  tax: 0,
  cost: 0,
  barcode: [],
  category: "",
  stock: 0,
  purchasePrice: 0,
  weightUnit: "",
  minInStock: 0,
  supplier: "",
  expiryDate: null,
  measureOfPurchase: "",
  measureOfUnit: "",
  productType: "S",
  weight: "",
  note: "",
  edit: false,
  target: 1,
  source: 1,
};

export default MultiStepForRetail;
