import {
  SET_SIDEBAR_STATUS,
  ADD_GYM_MODAL,
  ADD_TRAINER_MODAL,
  ADD_ADMIN_MODAL,
  ADD_SUB_ADMIN_MODAL,
  ADD_PRODUCT_MODAL,
} from "../types/types";

export const setSidebarStatus = (value) => {
  return {
    type: SET_SIDEBAR_STATUS,
    payload: { data: value },
  };
};

export const addGymModal = (value) => {
  return {
    type: ADD_GYM_MODAL,
    payload: { data: value },
  };
};

export const addTrainerModal = (value) => {
  return {
    type: ADD_TRAINER_MODAL,
    payload: { data: value },
  };
};
export const addProductModal = (value) => {
  return {
    type: ADD_PRODUCT_MODAL,
    payload: { data: value },
  };
};

export const addAdminModal = (value) => {
  return {
    type: ADD_ADMIN_MODAL,
    payload: { data: value },
  };
};

export const addSubAdminModal = (value, isEdit,fields) => {
  return {
    type: ADD_SUB_ADMIN_MODAL,
    payload: { data: value, isEdit,fields },
  };
};
