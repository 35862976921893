
import {
  SET_PAYEMNT_GATEWAYS_BY_REGION,
  SET_PAYMENT_GATEWAYS_FIELDS,
  SET_PAYMENT_GATEWAY_FIELDS_VALUE,
  SET__SAVE_PAYMENT_GATEWAY_FIELDS_VALUE,
  SET_PAYMENT_GATEWAYS_USED
   } from '../types/types';


export const setPaymentGateways = (value) => {
  return {
    type: SET_PAYEMNT_GATEWAYS_BY_REGION,
    payload: { data: value },
  };
};

export const setPaymentGatewaysFields = (value) => {
  return {
    type: SET_PAYMENT_GATEWAYS_FIELDS,
    payload: { data: value },
  };
};

export const setPaymentGatewayFieldSValue = (value) => {
  return {
    type: SET_PAYMENT_GATEWAY_FIELDS_VALUE,
    payload: { data: value },
  };
}

export const setSaveGatewayValueFields = (value) => {
  return {
    type: SET__SAVE_PAYMENT_GATEWAY_FIELDS_VALUE,
    payload: { data: value },
  };
}

export const setPaymentGatewaysUsed = (value) => {
  return {
    type: SET_PAYMENT_GATEWAYS_USED,
    payload: { data: value },
  };
}