import {
  SET_GYM_DATA,
  SET_GYMS_DATA,
  SET_GYM_COUNT,
  SET_BRANCHES_DATA,
  SET_SUPER_GYM_ADMIN_INFO,
  SET_SUPER_GYM_ADMIN_TDASHBOARD_TOTALS,
} from '../../actions/types/types';

const initState = {
  gyms: [],
  gym: {},
  gymCount: 0,
  branches: undefined,
  superGymAdminInfo:undefined,
  superGymDasboardTotals:{},
  governaces:[]
};

const GymReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_GYM_DATA:
      return {
        ...state,
        gym: action.payload.data,
      };
    case SET_GYMS_DATA:
      return {
        ...state,
        gyms: action.payload.data,
      };
    case SET_GYM_COUNT:
      return {
        ...state,
        gymCount: action.payload.data,
      };
    case SET_BRANCHES_DATA:
      return {
        ...state,
        branches: action.payload.data,
      };
    case SET_SUPER_GYM_ADMIN_INFO:
      return {
        ...state,
        superGymAdminInfo: action.payload.data,
      };
      case SET_SUPER_GYM_ADMIN_TDASHBOARD_TOTALS:
        return {
          ...state,
          superGymDasboardTotals: action.payload.data,
        };
        case "SET_GOVERNANCE_DATA":
          return {
            ...state,
            governaces: action.payload.data,
          };
    default:
      return { ...state };
  }
};

export default GymReducer;
