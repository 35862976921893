
import { SET_PAYEMNT_GATEWAYS_BY_REGION } from '../../actions/types/types';

const initState = {
  paymentGateways: [],
};

const paymentGateways = (state = initState, action) => {
  switch (action.type) {
    case SET_PAYEMNT_GATEWAYS_BY_REGION:
      return {
        ...state,
        paymentGateways: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default paymentGateways;
