import { SET_GYMS_DATA, SET_GYM_DATA, SET_GYM_COUNT, SET_BRANCHES_DATA, SET_SUPER_GYM_ADMIN_INFO, SET_SUPER_GYM_ADMIN_TDASHBOARD_TOTALS } from '../types/types';

export const setGymsData = (value) => {
  return {
    type: SET_GYMS_DATA,
    payload: { data: value },
  };
};
export const setGymData = (value) => {
  return {
    type: SET_GYM_DATA,
    payload: { data: value },
  };
};
export const setGymCount = (value) => {
  return {
    type: SET_GYM_COUNT,
    payload: { data: value },
  };
};
export const setBranchesData = (value) => {
  return {
    type: SET_BRANCHES_DATA,
    payload: { data: value },
  };
};
export const setGoverneData = (value) => {
  return {
    type: "SET_GOVERNANCE_DATA",
    payload: { data: value },
  };
};
export const setSuperGymAdminInfo = (value) => {
  return {
    type: SET_SUPER_GYM_ADMIN_INFO,
    payload: { data: value },
  };
};
export const setSuperGymAdminDashboardTotals = (value) => {
  return {
    type: SET_SUPER_GYM_ADMIN_TDASHBOARD_TOTALS,
    payload: { data: value },
  };
};
