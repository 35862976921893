import React from "react";

const comonIcons = {
  info: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>6AB99C0B-0DAC-41B6-B748-720E6DDEBA3A</title>
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.5"
      >
        <g id="Admin-Dashboard" transform="translate(-32.000000, -543.000000)">
          <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
            <g id="Info" transform="translate(0.000000, 462.000000)">
              <g
                id="person-24px-(2)"
                transform="translate(32.000000, 11.000000)"
              >
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M12,12 C14.21,12 16,10.21 16,8 C16,5.79 14.21,4 12,4 C9.79,4 8,5.79 8,8 C8,10.21 9.79,12 12,12 Z M12,14 C9.33,14 4,15.34 4,18 L4,19 C4,19.55 4.45,20 5,20 L19,20 C19.55,20 20,19.55 20,19 L20,18 C20,15.34 14.67,14 12,14 Z"
                  id="Shape"
                  fill="#000000"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  attachment: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      width="30px"
      height="20px"
      viewBox="0 0 24 24"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"></path>
      </g>
    </svg>
  ),
  eye: (
    <svg
      id="eye"
      xmlns="http://www.w3.org/2000/svg"
      width="20.773"
      height="20.3"
      viewBox="0 0 29.773 20.3"
    >
      <g id="Group_5705" data-name="Group 5705" transform="translate(0 0)">
        <g id="Group_5704" data-name="Group 5704" transform="translate(0 0)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M174.727,170.667a4.06,4.06,0,1,0,4.06,4.06A4.063,4.063,0,0,0,174.727,170.667Z"
            transform="translate(-159.84 -164.577)"
            fill="#7b00a3"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M14.887,74.667A16.006,16.006,0,0,0,0,84.817a15.992,15.992,0,0,0,29.773,0A16,16,0,0,0,14.887,74.667Zm0,16.917a6.767,6.767,0,1,1,6.767-6.767A6.769,6.769,0,0,1,14.887,91.584Z"
            transform="translate(0 -74.667)"
            fill="#7b00a3"
          />
        </g>
      </g>
    </svg>
  ),
  edit: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Gym-Admin"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="User-Info" transform="translate(-402.000000, -897.000000)">
          <g id="Group-2" transform="translate(348.000000, 780.000000)">
            <g id="Group" transform="translate(42.000000, 109.000000)">
              <g id="create-24px" transform="translate(12.000000, 8.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M3,17.46 L3,20.5 C3,20.78 3.22,21 3.5,21 L6.54,21 C6.67,21 6.8,20.95 6.89,20.85 L17.81,9.94 L14.06,6.19 L3.15,17.1 C3.05,17.2 3,17.32 3,17.46 Z M20.71,7.04 C21.1,6.65 21.1,6.02 20.71,5.63 L18.37,3.29 C17.98,2.9 17.35,2.9 16.96,3.29 L15.13,5.12 L18.88,8.87 L20.71,7.04 Z"
                  id="Shape"
                  fill="#7B00A3"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  gym: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.5"
      >
        <g id="Admin-Dashboard" transform="translate(-32.000000, -594.000000)">
          <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
            <g id="Gyms" transform="translate(0.000000, 513.000000)">
              <g
                id="fitness_center-24px"
                transform="translate(32.000000, 11.000000)"
              >
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M20.57,14.86 L21.29,14.14 C21.68,13.75 21.68,13.12 21.29,12.73 L21.27,12.71 C20.88,12.32 20.25,12.32 19.86,12.71 L17,15.57 L8.43,7 L11.29,4.14 C11.68,3.75 11.68,3.12 11.29,2.73 L11.27,2.71 C10.88,2.32 10.25,2.32 9.86,2.71 L9.14,3.43 L8.42,2.71 C8.03,2.32 7.39,2.32 7,2.71 L5.57,4.14 L4.85,3.42 C4.46,3.03 3.81,3.03 3.42,3.42 C3.03,3.81 3.03,4.46 3.42,4.85 L4.14,5.57 L2.71,7 C2.32,7.39 2.32,8.02 2.71,8.41 L3.43,9.13 L2.71,9.86 C2.32,10.25 2.32,10.88 2.71,11.27 L2.73,11.29 C3.12,11.68 3.75,11.68 4.14,11.29 L7,8.43 L15.57,17 L12.71,19.86 C12.32,20.25 12.32,20.88 12.71,21.27 L12.73,21.29 C13.12,21.68 13.75,21.68 14.14,21.29 L14.86,20.57 L15.58,21.29 C15.97,21.68 16.6,21.68 16.99,21.29 L18.42,19.86 L19.14,20.58 C19.53,20.97 20.18,20.97 20.57,20.58 C20.96,20.19 20.96,19.54 20.57,19.15 L19.85,18.43 L21.29,17 C21.68,16.61 21.68,15.98 21.29,15.59 L20.57,14.86 Z"
                  id="Path"
                  fill="#000000"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  trainer: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.5"
      >
        <g id="Admin-Dashboard" transform="translate(-32.000000, -648.000000)">
          <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
            <g id="Trainers" transform="translate(0.000000, 567.000000)">
              <g
                id="self_improvement-24px"
                transform="translate(32.000000, 11.000000)"
              >
                <g id="Group">
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  ></rect>
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  ></rect>
                </g>
                <g
                  id="Group"
                  transform="translate(3.000000, 4.000000)"
                  fill="#000000"
                  fill-rule="nonzero"
                >
                  <circle id="Oval" cx="9" cy="2" r="2"></circle>
                  <path
                    d="M18,10.94 L18,10.94 C18,10.44 17.64,10.01 17.15,9.96 C15.27,9.75 13.66,8.83 12.4,7.33 L11.06,5.73 C10.68,5.26 10.12,5 9.53,5 L8.48,5 C7.89,5 7.33,5.26 6.95,5.72 L5.61,7.32 C4.36,8.82 2.74,9.74 0.86,9.95 C0.36,10.01 0,10.44 0,10.94 L0,10.94 C0,11.54 0.53,12.01 1.13,11.94 C3.43,11.67 5.45,10.55 7,8.75 L7,11 L3.24,12.5 C2.59,12.76 2.08,13.33 2.01,14.03 C1.91,15.1 2.74,16 3.79,16 L6,16 L6,15.5 C6,14.12 7.12,13 8.5,13 L11.5,13 C11.78,13 12,13.22 12,13.5 C12,13.78 11.78,14 11.5,14 L8.5,14 C7.67,14 7,14.67 7,15.5 L7,16 L14.1,16 C14.95,16 15.75,15.46 15.95,14.63 C16.16,13.74 15.68,12.87 14.87,12.55 L11,11 L11,8.75 C12.56,10.55 14.57,11.66 16.87,11.94 C17.47,12 18,11.54 18,10.94 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  terms: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.5"
      >
        <g id="Admin-Dashboard" transform="translate(-32.000000, -702.000000)">
          <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
            <g id="Trainers" transform="translate(0.000000, 621.000000)">
              <g
                id="description-24px"
                transform="translate(32.000000, 11.000000)"
              >
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M14.59,2.59 C14.21,2.21 13.7,2 13.17,2 L6,2 C4.9,2 4,2.9 4,4 L4,20 C4,21.1 4.89,22 5.99,22 L18,22 C19.1,22 20,21.1 20,20 L20,8.83 C20,8.3 19.79,7.79 19.41,7.42 L14.59,2.59 Z M15,18 L9,18 C8.45,18 8,17.55 8,17 C8,16.45 8.45,16 9,16 L15,16 C15.55,16 16,16.45 16,17 C16,17.55 15.55,18 15,18 Z M15,14 L9,14 C8.45,14 8,13.55 8,13 C8,12.45 8.45,12 9,12 L15,12 C15.55,12 16,12.45 16,13 C16,13.55 15.55,14 15,14 Z M13,8 L13,3.5 L18.5,9 L14,9 C13.45,9 13,8.55 13,8 Z"
                  id="Shape"
                  fill="#000000"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  privacy: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.5"
      >
        <g id="Admin-Dashboard" transform="translate(-32.000000, -756.000000)">
          <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
            <g id="Users" transform="translate(0.000000, 675.000000)">
              <g
                id="privacy_tip-24px"
                transform="translate(32.000000, 11.000000)"
              >
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <path
                  d="M4.19,4.47 C3.47,4.79 3,5.51 3,6.3 L3,11 C3,16.55 6.84,21.74 12,23 C17.16,21.74 21,16.55 21,11 L21,6.3 C21,5.51 20.53,4.79 19.81,4.47 L12.81,1.36 C12.29,1.13 11.7,1.13 11.19,1.36 L4.19,4.47 Z M12,7 L12,7 C12.55,7 13,7.45 13,8 L13,8 C13,8.55 12.55,9 12,9 L12,9 C11.45,9 11,8.55 11,8 L11,8 C11,7.45 11.45,7 12,7 Z M12,11 L12,11 C12.55,11 13,11.45 13,12 L13,16 C13,16.55 12.55,17 12,17 L12,17 C11.45,17 11,16.55 11,16 L11,12 C11,11.45 11.45,11 12,11 Z"
                  id="Shape"
                  fill="#000000"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  mailtemplate: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.5"
      >
        <g id="Admin-Dashboard" transform="translate(-32.000000, -810.000000)">
          <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
            <g
              id="Manage-EMail-Templates"
              transform="translate(0.000000, 729.000000)"
            >
              <g
                id="all_inbox-24px"
                transform="translate(32.000000, 11.000000)"
              >
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,12 C3,13.1 3.9,14 5,14 L19,14 C20.1,14 21,13.1 21,12 L21,5 C21,3.9 20.1,3 19,3 Z M19,9 L15.86,9 C15.39,9 15.02,9.33 14.89,9.78 C14.53,11.04 13.35,12 12,12 C10.65,12 9.47,11.04 9.11,9.78 C8.98,9.33 8.61,9 8.14,9 L5,9 L5,6 C5,5.45 5.45,5 6,5 L18,5 C18.55,5 19,5.45 19,6 L19,9 Z M15.87,16 L20,16 C20.55,16 21,16.45 21,17 L21,19 C21,20.1 20.1,21 19,21 L5,21 C3.9,21 3,20.1 3,19 L3,17 C3,16.45 3.45,16 4,16 L8.13,16 C8.6,16 8.98,16.34 9.11,16.8 C9.46,18.07 10.62,19 12,19 C13.38,19 14.54,18.07 14.89,16.8 C15.02,16.34 15.4,16 15.87,16 Z"
                  id="Shape"
                  fill="#000000"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  social: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.5"
      >
        <g id="Admin-Dashboard" transform="translate(-32.000000, -864.000000)">
          <g id="Side-Menu" transform="translate(0.000000, 70.000000)">
            <g id="Social-media" transform="translate(0.000000, 783.000000)">
              <g id="share-24px" transform="translate(32.000000, 11.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M18,16.08 C17.24,16.08 16.56,16.38 16.04,16.85 L8.91,12.7 C8.96,12.47 9,12.24 9,12 C9,11.76 8.96,11.53 8.91,11.3 L15.96,7.19 C16.5,7.69 17.21,8 18,8 C19.66,8 21,6.66 21,5 C21,3.34 19.66,2 18,2 C16.34,2 15,3.34 15,5 C15,5.24 15.04,5.47 15.09,5.7 L8.04,9.81 C7.5,9.31 6.79,9 6,9 C4.34,9 3,10.34 3,12 C3,13.66 4.34,15 6,15 C6.79,15 7.5,14.69 8.04,14.19 L15.16,18.35 C15.11,18.56 15.08,18.78 15.08,19 C15.08,20.61 16.39,21.92 18,21.92 C19.61,21.92 20.92,20.61 20.92,19 C20.92,17.39 19.61,16.08 18,16.08 Z"
                  id="Path"
                  fill="#000000"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  admin: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.5"
      >
        <g id="Gym-Info" transform="translate(-32.000000, -836.000000)">
          <g id="Social-media" transform="translate(0.000000, 825.000000)">
            <g
              id="admin_panel_settings-24px"
              transform="translate(32.000000, 11.000000)"
            >
              <g id="Group">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              </g>
              <g
                id="Group"
                transform="translate(3.000000, 3.000000)"
                fill="#000000"
                fill-rule="nonzero"
              >
                <path
                  d="M14,8 C14.34,8 14.67,8.04 15,8.09 L15,4.58 C15,3.78 14.53,3.06 13.8,2.75 L8.3,0.35 C7.79,0.13 7.21,0.13 6.7,0.35 L1.2,2.75 C0.47,3.07 0,3.79 0,4.58 L0,8.18 C0,12.72 3.2,16.97 7.5,18 C8.05,17.87 8.58,17.68 9.1,17.45 C8.41,16.47 8,15.28 8,14 C8,10.69 10.69,8 14,8 Z"
                  id="Path"
                ></path>
                <path
                  d="M14,10 C11.79,10 10,11.79 10,14 C10,16.21 11.79,18 14,18 C16.21,18 18,16.21 18,14 C18,11.79 16.21,10 14,10 Z M14,11.38 C14.62,11.38 15.12,11.89 15.12,12.5 C15.12,13.11 14.61,13.62 14,13.62 C13.39,13.62 12.88,13.11 12.88,12.5 C12.88,11.89 13.38,11.38 14,11.38 Z M14,16.75 C13.07,16.75 12.26,16.29 11.76,15.58 C11.81,14.86 13.27,14.5 14,14.5 C14.73,14.5 16.19,14.86 16.24,15.58 C15.74,16.29 14.93,16.75 14,16.75 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  accounting: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        opacity="0.5"
      >
        <g
          id="Editing-Super-Admin-Info"
          transform="translate(-32.000000, -836.000000)"
        >
          <g id="Accounting-Tab" transform="translate(0.000000, 825.000000)">
            <g
              id="card_membership-24px"
              transform="translate(32.000000, 11.000000)"
            >
              <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
              <path
                d="M20,2 L4,2 C2.89,2 2,2.89 2,4 L2,15 C2,16.11 2.89,17 4,17 L8,17 L8,22 L12,20 L16,22 L16,17 L20,17 C21.11,17 22,16.11 22,15 L22,4 C22,2.89 21.11,2 20,2 Z M20,15 L4,15 L4,13 L20,13 L20,15 Z M20,10 L4,10 L4,5 C4,4.45 4.45,4 5,4 L19,4 C19.55,4 20,4.45 20,5 L20,10 Z"
                id="Shape"
                fill="#000000"
                fill-rule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  excel: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Exporting-Data" transform="translate(-1169.000000, -212.000000)">
          <g id="Excel" transform="translate(1169.000000, 212.000000)">
            <image id="Bitmap" x="2" y="3" width="20" height="18"></image>
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          </g>
        </g>
      </g>
    </svg>
  ),
  pdf: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Exporting-Data" transform="translate(-1169.000000, -258.000000)">
          <g
            id="picture_as_pdf-24px"
            transform="translate(1169.000000, 258.000000)"
          >
            <polygon id="Path" points="0 0 24 0 24 24 0 24" />
            <path
              d="M20,2 L8,2 C6.9,2 6,2.9 6,4 L6,16 C6,17.1 6.9,18 8,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M11.5,9.5 C11.5,10.33 10.83,11 10,11 L9,11 L9,13 L7.5,13 L7.5,7 L10,7 C10.83,7 11.5,7.67 11.5,8.5 L11.5,9.5 Z M16.5,11.5 C16.5,12.33 15.83,13 15,13 L12.5,13 L12.5,7 L15,7 C15.83,7 16.5,7.67 16.5,8.5 L16.5,11.5 Z M20.5,8.5 L19,8.5 L19,9.5 L20.5,9.5 L20.5,11 L19,11 L19,13 L17.5,13 L17.5,7 L20.5,7 L20.5,8.5 Z M9,9.5 L10,9.5 L10,8.5 L9,8.5 L9,9.5 Z M4,6 L2,6 L2,20 C2,21.1 2.9,22 4,22 L18,22 L18,20 L4,20 L4,6 Z M14,11.5 L15,11.5 L15,8.5 L14,8.5 L14,11.5 Z"
              id="Shape"
              fill="#4A90E2"
              fill-rule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  downarrow: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Admin-account"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Admin-Dashboard" transform="translate(-342.000000, -270.000000)">
          <g id="Group-10" transform="translate(324.000000, 166.000000)">
            <g
              id="arrow_drop_up-24px"
              transform="translate(18.000000, 104.000000)"
            >
              <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
              <path
                d="M8.71,12.29 L11.3,9.7 C11.69,9.31 12.32,9.31 12.71,9.7 L15.3,12.29 C15.93,12.92 15.48,14 14.59,14 L9.41,14 C8.52,14 8.08,12.92 8.71,12.29 Z"
                id="Path"
                fill="#417505"
                fill-rule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g>
        <g>
          <path
            fill="#17468f"
            d="M0 13C0 5.82 5.82 0 13 0s13 5.82 13 13-5.82 13-13 13S0 20.18 0 13z"
          />
        </g>
        <g>
          <path
            fill="#fff"
            d="M13.825 13.36L17.784 9.4a.357.357 0 1 0-.505-.504l-3.958 3.958-3.959-3.958a.357.357 0 1 0-.504.504l3.958 3.959-3.958 3.958a.357.357 0 1 0 .504.505l3.959-3.959 3.958 3.959a.356.356 0 0 0 .505 0 .357.357 0 0 0 0-.505z"
          />
          <path
            fill="none"
            stroke="#fff"
            stroke-miterlimit="20"
            d="M13.825 13.36v0L17.784 9.4a.357.357 0 1 0-.505-.504l-3.958 3.958v0L9.362 8.897a.357.357 0 1 0-.504.504l3.958 3.959v0l-3.958 3.958a.357.357 0 1 0 .504.505l3.959-3.959v0l3.958 3.959a.356.356 0 0 0 .505 0 .357.357 0 0 0 0-.505z"
          />
        </g>
      </g>
    </svg>
  ),
  startloop: (
    <svg
      width="15px"
      height="15px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Finals"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Gyms-tab" transform="translate(-1174.000000, -265.000000)">
          <g id="Gym-Card" transform="translate(460.000000, 235.000000)">
            <g id="Kettlebell" transform="translate(714.000000, 30.000000)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <path
                d="M20.3279699,2.46264328 C20.1567339,2.16601407 19.9420822,1.8923062 19.6896777,1.64853092 C19.4370036,1.40448598 19.0705317,1.12835115 18.7477452,0.951452271 C14.632689,-0.395244358 9.00859908,-0.215379189 5.19718335,0.884306204 C4.88113841,1.05931744 4.28329571,1.41176688 4.03925077,1.65311519 C3.79493616,1.89473317 3.58702605,2.16628373 3.4157901,2.46264328 C2.7313856,3.64835115 2.83223953,5.18354216 3.69300358,6.67450845 L5.80176762,10.3268231 C4.41974515,11.7970253 3.57273392,13.7782388 3.57273392,15.9554972 C3.57273392,19.8286658 6.25372268,23.0759467 9.86100358,23.9434523 L13.7163744,23.9434523 C17.3241946,23.0759467 20.0049137,19.8286658 20.0049137,15.9554972 C20.0049137,13.8327107 19.1999699,11.8951826 17.8780822,10.4371152 L20.0507564,6.67450845 C20.9112508,5.18354216 21.0123744,3.64835115 20.3279699,2.46264328 Z M16.9722845,6.58255339 C16.7535878,6.96169946 16.4982171,7.40394665 16.2247789,7.87774441 C16.0637901,8.15657587 15.8965991,8.44619384 15.7264418,8.74039609 C14.557453,8.10075564 13.2156103,7.73698036 11.7890935,7.73698036 C10.4135429,7.73698036 9.1175429,8.07540733 7.97848672,8.67298036 C7.82181257,8.40196913 7.6681047,8.1352725 7.51925077,7.87801407 C7.24581257,7.40421632 6.99044178,6.96196913 6.77147549,6.58255339 C6.29390245,5.67810396 5.33255414,4.7170253 5.84518335,3.62731744 C6.10163279,3.17859834 6.9168238,3.0494298 7.45183504,3.00628373 C7.45183504,3.00628373 7.80805976,2.93239609 8.68958785,2.87360957 C11.341453,2.6967107 13.8633407,2.63280059 16.8226216,3.05050845 C17.0148912,3.11441856 17.4460822,3.24277811 17.6380822,3.370868 C18.5352508,4.46057587 17.5099923,5.61392418 16.9722845,6.58255339 Z"
                id="Shape"
                fill="#F6A51F"
                fill-rule="nonzero"
              ></path>
              <g id="star-24px" transform="translate(5.000000, 9.000000)">
                <g id="Group">
                  <polygon id="Path" points="0 0 14 0 14 14 0 14"></polygon>
                  <polygon id="Path" points="0 0 14 0 14 14 0 14"></polygon>
                </g>
                <path
                  d="M7,10.0741667 L9.42083333,11.5383333 C9.86416667,11.8066667 10.4066667,11.41 10.29,10.9083333 L9.64833333,8.155 L11.7891667,6.3 C12.18,5.96166667 11.97,5.32 11.4566667,5.27916667 L8.63916667,5.04 L7.53666667,2.43833333 C7.33833333,1.96583333 6.66166667,1.96583333 6.46333333,2.43833333 L5.36083333,5.03416667 L2.54333333,5.27333333 C2.03,5.31416667 1.82,5.95583333 2.21083333,6.29416667 L4.35166667,8.14916667 L3.71,10.9025 C3.59333333,11.4041667 4.13583333,11.8008333 4.57916667,11.5325 L7,10.0741667 Z"
                  id="Path"
                  fill="#9E6A14"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  download: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-download"
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline points="7 10 12 15 17 10"></polyline>
      <line x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
  ),
  delete: (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Gym-Admin"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Gym-Admin-Packages"
          transform="translate(-520.000000, -920.000000)"
        >
          <g id="Group-16-Copy-2" transform="translate(348.000000, 803.000000)">
            <g id="Group-3" transform="translate(160.000000, 109.000000)">
              <g id="delete-24px" transform="translate(12.000000, 8.000000)">
                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                <path
                  d="M6,19 C6,20.1 6.9,21 8,21 L16,21 C17.1,21 18,20.1 18,19 L18,9 C18,7.9 17.1,7 16,7 L8,7 C6.9,7 6,7.9 6,9 L6,19 Z M18,4 L15.5,4 L14.79,3.29 C14.61,3.11 14.35,3 14.09,3 L9.91,3 C9.65,3 9.39,3.11 9.21,3.29 L8.5,4 L6,4 C5.45,4 5,4.45 5,5 C5,5.55 5.45,6 6,6 L18,6 C18.55,6 19,5.55 19,5 C19,4.45 18.55,4 18,4 Z"
                  id="Shape"
                  fill="#D0021B"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  print: (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V11C20.6569 11 22 12.3431 22 14V18C22 19.6569 20.6569 21 19 21H5C3.34314 21 2 19.6569 2 18V14C2 12.3431 3.34315 11 5 11V5ZM5 13C4.44772 13 4 13.4477 4 14V18C4 18.5523 4.44772 19 5 19H19C19.5523 19 20 18.5523 20 18V14C20 13.4477 19.5523 13 19 13V15C19 15.5523 18.5523 16 18 16H6C5.44772 16 5 15.5523 5 15V13ZM7 6V12V14H17V12V6H7ZM9 9C9 8.44772 9.44772 8 10 8H14C14.5523 8 15 8.44772 15 9C15 9.55228 14.5523 10 14 10H10C9.44772 10 9 9.55228 9 9ZM9 12C9 11.4477 9.44772 11 10 11H14C14.5523 11 15 11.4477 15 12C15 12.5523 14.5523 13 14 13H10C9.44772 13 9 12.5523 9 12Z"
          fill="#000000"
        ></path>{" "}
      </g>
    </svg>
  ),
};

export const ComonIcons = comonIcons;
