import React, { useCallback, useEffect, useRef, useState } from "react";
import { ProductInfoEdit } from "../../../contents/htmlContent";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import Title from "../../title";
import { Field } from "formik";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { api } from "../../../api/api";
import { useSelector } from "react-redux";
import { InventorySection } from "./firstStepFormInventory";
import { BarcodeSection } from "./firstStepFormInventoryBarcode";

const DropZone = ({ values, setFieldValue, errors }) => {
  const maxSize = 10485760;
  const onDrop = useCallback((acceptedFiles) => {
    let file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        Resizer.imageFileResizer(
          file,
          300,
          300,
          "PNG",
          100,
          0,
          async (uri) => {
            let data = {
              fileName: acceptedFiles[0].name,
              source: uri,
              folder: "profile",
            };
            var url = await api(`file`, data, "post");
            if (url.data.status === 201) {
              setFieldValue("image", url.data.data);
            }
          },
          "base64",
          300,
          300
        );
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: 10485760,
    accept: "image/*",
  });
  return (
    <div className="upload-profilephoto">
      <div className="file-input" {...getRootProps()}>
        <h6 className="upload-link-product mb-0"> Upload image </h6>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

export const FormFirstStep = (formikProps) => {
  const { errors, touched, values, setFieldValue, edit } = formikProps;
  const { superGymAdminInfo } = useSelector((state) => state.gym);
  const existingCategories = superGymAdminInfo?.category
    ? superGymAdminInfo.category
    : [];
  const [selectedCategory, setSelectedCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [showOptions, setShowOptions] = useState(false);

  const inputRef = useRef(null);
  const weightUnits = [
    "Gram (g)",
    "Kilogram (kg)",
    "Milligram (mg)",
    "Metric ton (t)",
    "Pound (lb)",
    "Ounce (oz)",
    "Carat (ct)",
    "Tonne (t)",
    "Stone (st)",
    "Grain (gr)",
  ];

  console.log({ edit, values, errors });
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCategoryChange = (category) => {
    console.log({ category });
    setSelectedCategory(category);
    setFieldValue("category", category);
    setNewCategory("");
    setShowOptions(false);
    inputRef.current.value = category;
  };

  const handleNewCategoryChange = (e) => {
    setNewCategory(e.target.value);
    setSelectedCategory("");
    setShowOptions(true);
    setFieldValue("category", e.target.value);
  };

  const filteredCategories = existingCategories.filter((category) =>
    category.toLowerCase().includes(newCategory.toLowerCase())
  );
  return (
    <>
      <div className=" bg-white card-panel">
        <div className="basic-info">
          <div className="info-product-title with-border">
            <Title
              title="Add Retail Product"
              subtitlestyle="ctf-tx-secondary"
              subtitle="Add info about the Retail Product"
            />
          </div>
        </div>
        <div className="profile-image-block blk-padding-y border-bottom-1">
          <div className="profile-image-inner-block d-flex justify-content-center">
            <div className="profile-image-wrapper">
              <div className="profile-image">
                {values.image ? (
                  <img
                    src={values.image}
                    className="img-fluid img-blk"
                    alt="user"
                  />
                ) : (
                  <div className="image-product-link"></div>
                )}
              </div>

              <div className="profile-image-links">
                <div className="links file-input-link">
                  <div className="file-input">
                    <DropZone
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="member-info-wrapper info-wrapper border-bottom-1 blk-padding-y">
          <div className="info-product-title">
            <Title title={ProductInfoEdit.infotitle} />
          </div>
          <div className="info trainer-info">
            <Row>
              <Col md={6}>
                <FormGroup className="custom-inputs ">
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    maxLength={50}
                    placeholder={ProductInfoEdit.namePlaceHolder}
                    className={`form-control ${errors.name && "is-invalid"}`}
                  />
                  {errors.name && touched.name ? (
                    <div className="invalid-feedback">{`${errors.name}`}</div>
                  ) : null}
                  <Label for="name" className="">
                    {ProductInfoEdit.name}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="custom-inputs ">
                  <Field
                    type="text"
                    name="type"
                    id="type"
                    maxLength={50}
                    placeholder={ProductInfoEdit.typePlaceHolder}
                    className={`form-control ${errors.type && "is-invalid"}`}
                  />
                  {errors.type && touched.type ? (
                    <div className="invalid-feedback">{`${errors.type}`}</div>
                  ) : null}
                  <Label for="type" className="">
                    {ProductInfoEdit.type}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="custom-inputs ">
                  <Field
                    type="number"
                    name="price"
                    id="price"
                    placeholder={ProductInfoEdit.price}
                    disabled={edit}
                    className={`form-control ${errors.price && "is-invalid"}`}
                  />
                  {errors.price && touched.price ? (
                    <div className="invalid-feedback">{`${errors.price}`}</div>
                  ) : null}
                  <Label for="name" className="">
                    {ProductInfoEdit.price}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="custom-inputs ">
                  <Field
                    type="number"
                    name="tax"
                    id="tax"
                    disabled={edit}
                    placeholder={ProductInfoEdit.taxPlaceHolder}
                    className={`form-control `}
                  />
                  <Label for="name" className="">
                    {ProductInfoEdit.tax}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="custom-inputs ">
                  <Input
                    type="text"
                    placeholder="Click to select or add new category"
                    value={selectedCategory || newCategory}
                    onFocus={() => setShowOptions(true)}
                    onChange={handleNewCategoryChange}
                    onBlur={() => setShowOptions(false)}
                    innerRef={inputRef}
                    readOnly={edit}
                    className={`form-control ${
                      errors.category && "is-invalid"
                    }`}
                  />
                  {showOptions && (
                    <ListGroup>
                      {filteredCategories.map((category) => (
                        <ListGroupItem
                          key={category}
                          onMouseDown={() => handleCategoryChange(category)}
                          action
                        >
                          {category}
                        </ListGroupItem>
                      ))}
                      {newCategory && (
                        <ListGroupItem
                          onMouseDown={() => handleCategoryChange(newCategory)}
                          action
                        >
                          Add New: {newCategory}
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  )}
                  <Label for="name" className="">
                    {ProductInfoEdit.category}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="custom-inputs ">
                  <Field
                    type="string"
                    name="weight"
                    id="weight"
                    disabled={edit}
                    placeholder={"weight"}
                    className={`form-control`}
                  />
                  <Label for="name" className="">
                    {"Weight"}
                  </Label>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="custom-inputs ">
                  <Input
                    type="select"
                    name="weightUnit"
                    id="weightUnit"
                    disabled={edit}
                    placeholder={"weightUnit"}
                    className={`form-control`}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setFieldValue("weightUnit", e.target.value);
                    }}
                  >
                    <option value={""}>select unit </option>
                    {weightUnits.map((weight) => {
                      return (
                        <option key={weight} value={weight}>
                          {weight}
                        </option>
                      );
                    })}
                  </Input>
                  <Label for="name" className="">
                    {"Weigh Unit"}
                  </Label>
                </FormGroup>
              </Col>
              <BarcodeSection
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                edit={edit}
              />
            </Row>
            <div className="status-inner-block">
              <div className="status-inner-block">
                <div className="subscription-plan-list-element">
                  <div className="">
                    <div className="form-check form-check-inline custom-btn-radio">
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="productType"
                        id="inlineRadio586"
                        value="S"
                      />
                      <label
                        className="form-check-label label-blk"
                        for="inlineRadio586"
                      >
                        can be sold
                      </label>
                    </div>
                  </div>
                </div>
                <div className="subscription-plan-list-element">
                  <div className="">
                    <div
                      className="form-check form-check-inline custom-btn-radio"
                      checked
                    >
                      <Field
                        className="form-check-input"
                        type="radio"
                        name="productType"
                        id="inlineRadio587"
                        value="P"
                      />
                      <label
                        className="form-check-label label-blk"
                        for="inlineRadio587"
                      >
                        can be purchased
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <InventorySection
          errors={errors}
          touched={touched}
          values={values}
          setFieldValue={setFieldValue}
          edit={edit}
        />
        <div className="member-info-wrapper info-wrapper border-bottom-1 blk-padding-y">
          <div className="info-product-title mt-4 ">
            <Title title={"Additional Info"} />
          </div>
          <Col md={12} className="pl-1">
            <FormGroup className="custom-inputs pl-0 mb-0">
              <Input
                type="textarea"
                rows="3"
                style={{ height: "128px" }}
                name="note"
                className={`${
                  formikProps.errors.note &&
                  formikProps.touched.note &&
                  "is-invalid"
                }`}
                placeholder="Enter any Notes"
                onChange={formikProps.handleChange}
                value={formikProps.values.note}
              />
              <Label for="note">Notes</Label>
            </FormGroup>
          </Col>
        </div>
      </div>
    </>
  );
};
